import React, { useRef } from 'react'
import './trackList.scss'

interface RoundsProps {
  rounds: RoundProps[]
  currentRound: string
}

interface RoundProps {
  id: string
  name: string
  duration: number
}

const TrackList: React.FC<RoundsProps> = ({ rounds, currentRound }) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  const isDragging = useRef(false)
  const startX = useRef(0)
  const scrollLeft = useRef(0)

  const handleMouseDown = (e: any) => {
    isDragging.current = true
    if (scrollRef.current) {
      startX.current = e.pageX - scrollRef.current.offsetLeft
      scrollLeft.current = scrollRef.current.scrollLeft
    }
  }

  const handleMouseMove = (e: any) => {
    if (scrollRef.current) {
      const x = e.pageX - scrollRef.current.offsetLeft
      const walk = x - startX.current // Calculate the distance dragged
      scrollRef.current.scrollLeft = scrollLeft.current - walk
    }
  }

  const handleMouseUp = () => {
    isDragging.current = false
  }

  const handleTouchStart = (e: any) => {
    isDragging.current = true
    if (scrollRef.current) {
      startX.current = e.touches[0].pageX - scrollRef.current.offsetLeft
      scrollLeft.current = scrollRef.current.scrollLeft
    }
  }

  const handleTouchMove = (e: any) => {
    if (!scrollRef.current) return
    if (!isDragging.current) return
    const x = e.touches[0].pageX - scrollRef.current.offsetLeft
    const walk = x - startX.current
    scrollRef.current.scrollLeft = scrollLeft.current - walk
  }

  const handleTouchEnd = () => {
    isDragging.current = false
  }

  return (
    <div
      className="track-list"
      ref={scrollRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {rounds.map((round) => (
        <div key={round.id} className={`track-item ${round.id === currentRound ? 'active' : ''}`}>
          {/* {track.id - 1 === activeTrackId && <div className="track-arrow"></div>} */}
          <div className="track-title">{round.name.toUpperCase()}</div>
          <div className="track-duration">
            <div className="track-progress" style={{ width: `${round.duration * 10}%` }}></div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TrackList
