import React, { useId, useEffect, useState  } from 'react'
import HalfCircleProgress from "./half-circle-progress";
import { INRWorkoutSessionHit } from "interfaces/workout";
import { GroupWorkoutHit, GroupBag } from "interfaces/group-workout";
import { getEvaluatedIntensity, getHitsLastTenSeconds } from 'lib/intensity'

const colors: string[] = [
  "#FF7733",
  "#F7D126",
  "#F726D1",
  "#F0F726",
  "#F04D26",
  "#7E68AE",
  "#7857FF",
  "#75C7FF",
  "#70ED99",
  "#00AFB9",
];

// Props for GroupIntensity component
interface GroupIntensityProps {
  groupBags: GroupBag[];
  groupWorkoutHits: GroupWorkoutHit[];
  weight?: number;
  exerciseSecond: number;
  serverTime: number | null;
}

// GroupIntensity component
const GroupIntensity: React.FC<GroupIntensityProps> = ({
  groupBags,
  groupWorkoutHits,
  weight = 75,
  exerciseSecond,
  serverTime,
}) => {
  const hitsByBag = groupWorkoutHits.reduce((acc, hit) => {
    const bagId = hit.bag.id;
    if (!acc[bagId]) {
      acc[bagId] = [];
    }
    const sessionHit: INRWorkoutSessionHit = {
      angle: 0, // Default value
      force: hit.force,
      height: 0, // Default value
      punchedAt: hit.punchedAt,
    };

    acc[bagId].push(sessionHit);
    return acc;
  }, {} as Record<string, INRWorkoutSessionHit[]>);

  // Calculate intensity for each bag
  const bagIntensities = groupBags.map((groupBag, index) => {
    const bagId = groupBag.bag.id;
    const hitsForBag = hitsByBag[bagId] || [];
    const currentTime: number = serverTime !== null ? new Date(serverTime).getTime() : new Date().getTime();
    let lastHitSeconds = 10000;
    if (exerciseSecond < 10)
    {
      lastHitSeconds = exerciseSecond*1000;
    }
    const hitsLastTenSeconds = getHitsLastTenSeconds(hitsForBag, currentTime, lastHitSeconds)
    const intensity = getEvaluatedIntensity(hitsLastTenSeconds, weight, exerciseSecond);
    return {
      bagId,
      intensity,
      hitsCount: hitsForBag.length,
      gradientStart: colors[index], // Use colors array for gradient
      gradientEnd: "#ffffff", // Default gradient end color
    };
  });

  return (
    <div>
      <h2>Group Intensity</h2>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {bagIntensities.map(({ bagId, intensity, gradientStart, gradientEnd }, index) => (
          <div key={bagId} style={{ textAlign: "center" }}>
            <HalfCircleProgress
              value={intensity}
              maxValue={7}
              gradientStart={gradientStart}
              gradientEnd={gradientEnd}
              uniqueId={`gradient-${bagId}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupIntensity;
