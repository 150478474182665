import React, { useState } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import useFileUpload  from 'api/mutations/file'
import './index.scss';

const CREATE_GROUP_WORKOUT = gql`
  mutation createGroupWorkout($input: GroupWorkoutInputSchema!) {
    createGroupWorkout(input: $input) {
      id
      name
      audioFile {
        id
        originalName
        downloadLocation
      }
      thumbnailFile {
        id
        originalName
        downloadLocation
      }
    }
  }
`;

function CreateGroupWorkoutForm() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [duration, setDuration] = useState('');
  const [description, setDescription] = useState('');
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);

  const [createGroupWorkout, { error }] = useMutation(CREATE_GROUP_WORKOUT);
  const { uploadFile } = useFileUpload();
  const [loading, setLoading] = useState(false);

  const handleAudioFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setAudioFile(e.target.files[0]);
    }
  };

  const handleThumbnailFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setThumbnailFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    if (loading)
    {
      return;
    }
    setLoading(true)
    e.preventDefault();
    if (!thumbnailFile) {
      alert('Please select a thumbnail file first.');
      setLoading(false)
      return;
    }
    if (!audioFile) {
      alert('Please select a audio file first.');
      setLoading(false)
      return;
    }
    if (!name.trim()) {
      alert('Please provide a name for the group workout.');
      setLoading(false)
      return;
    }
    
    if (!duration || isNaN(parseInt(duration, 10))) {
      alert('Please provide a valid duration.');
      setLoading(false)
      return;
    }

    try {
      const audioFileResult = await uploadFile(audioFile);
      if (!audioFileResult) {
        alert('Audio file upload failed.');
        setLoading(false)
        return;
      }
      const thumbnailFileResult = await uploadFile(thumbnailFile);
      if (!thumbnailFileResult) {
        alert('Thumbnail file upload failed.');
        setLoading(false)
        return;
      }
      // 5. Create the GroupWorkout
      const workoutResp = await createGroupWorkout({
        variables: {
          input: {
            name,
            duration: parseInt(duration, 10),
            description,
            thumbnailFile: { id: thumbnailFileResult.fileId }, 
            audioFile: { id: audioFileResult.fileId }, 
          },
        },
      });

      alert('Group workout created successfully!');
      console.log('Created Group Workout:', workoutResp.data.createGroupWorkout);
      setLoading(false)
      navigate(`/group-workout/${workoutResp.data.createGroupWorkout.id}/edit`);
    } catch (err) {
      setLoading(false)
      console.error('Error:', err);
      alert('Error creating group workout with file upload.');
    }
  };

  return (
    <div className="groupWorkoutForm">
  <form className="groupWorkoutForm__form" onSubmit={handleSubmit}>
    <div>
      <label>Name:</label>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
    </div>
    <div>
      <label>Duration (minutes):</label>
      <input
        type="number"
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
        required
      />
    </div>
    <div>
      <label>Description:</label>
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </div>
    <div>
      <label>Thumbnail File:</label>
      <input type="file" accept="image/*" onChange={handleThumbnailFileChange} />
    </div>
    <div>
      <label>Audio File:</label>
      <input type="file" accept="audio/*" onChange={handleAudioFileChange} />
    </div>
    <button type="submit" disabled={loading}>
      {loading ? 'Processing...' : 'Create Group Workout'}
    </button>
    {error && <p className="groupWorkoutForm__error">Error: {error.message}</p>}
  </form>
</div>
  );
}

export default CreateGroupWorkoutForm;
