// types with "session" in its name represent the frontend type. This is because the Type required for API might have a different shape

import { User } from './user'

export enum RoundModes {
  TRYOUT = 'tryout',
  WORKOUT = 'workout',
}

export enum PlayerNames {
  PLAYER_1 = 'player1',
  PLAYER_2 = 'player2',
}

export enum DifficultyLevel {
  STARTER = 'Starter',
  EXPERIENCED = 'Experienced',
  PRO = 'Pro',
}

export enum WorkoutType {
  KICKBOXING = 'Kickboxing',
  BOXING = 'Boxing',
}

export enum WorkoutName {
  FREESTYLE = 'Freestyle',
  QUICKSTART = 'Quickstart',
}

export interface NRImage {
  downloadLocation: string
}

export interface INRWorkoutSessionHit {
  angle: number
  force: number
  height: number
  punchedAt: string
  punchedAtTimstamp?: number
}

export interface INRWorkoutSessionTime {
  [second: number]: INRWorkoutSessionHit[]
}

export enum ConstraintName {
  Hits = 'Hits',
  AvgForce = 'AvgForce',
  TotalForce = 'TotalForce',
  AvgIntensity = 'AvgIntensity',
}

export interface INRWorkoutSessionConstraint {
  max: number | null
  min: number | null
  showInUI: boolean
  type: ConstraintName
}

export interface INRWorkoutSessionChallenge {
  id: string
  title: string
  description: string
  failMessage: string
  successMessage: string
  constraints: INRWorkoutSessionConstraint[]
}

export interface INRWorkoutSessionExercise {
  seconds: number
  hits: INRWorkoutSessionHit[]
  breakSeconds: number
  videoUrl?: string | null
  image?: NRImage | null
  challenges: INRWorkoutSessionChallenge[] | null
  started: Date | null
  // fake?
  exercise?: number
  calories?: number
}

export interface INRWorkoutSessionRound {
  countdownSeconds: number
  breakSeconds: number
  exercises: INRWorkoutSessionExercise[]
  videoUrl?: string | null
  image?: NRImage | null
  // fake?
  round?: number
}

export interface INRWorkoutSession {
  id: string | null
  name: WorkoutName | string | null
  rounds: INRWorkoutSessionRound[]
  workoutType: WorkoutType
  level: DifficultyLevel
  videoUrl?: string | null
  image?: NRImage | null
  overviewImage?: NRImage | null
  // unused underneath
  player1?: INRWorkoutSessionRound[]
  player2?: INRWorkoutSessionRound[]
  variants?: INRWorkoutVariant[]
}

export interface INRWorkoutVariant {
  difficultyLevel: DifficultyLevel
  rounds: INRRound[]
}

export interface INRMove {
  name: string
}

export interface INRExercise {
  seconds: number
  moves?: unknown
  videoUrl?: string | null
  started: Date | null
  // ! waiting for Backend fix
  challenges?: INRWorkoutSessionChallenge[] | null
  breakSeconds: number
  calories: number
  image?: NRImage
  hits?: INRWorkoutSessionHit[]
}

export type INRRound = Omit<INRWorkoutSessionRound, 'exercises' | 'round'> & {
  exercises: INRExercise[]
}

export interface INRWorkoutTemplate {
  id: string
  image?: NRImage | null
  overviewImage?: NRImage | null
  rank: number | null
  name: string
  duration: number | null
  variants: INRWorkoutVariant[]
  videoUrl?: string | null
  workoutType: WorkoutType
}

export interface INRWorkoutCategory {
  id: string
  name: string
  rank: number | null
  templates: INRWorkoutTemplate[]
}

export interface INRWorkoutLeaderboard {
  id: string
  name: string
  records: INRWorkoutLeaderboardRecord[]
}

export interface INRWorkoutLeaderboardRecord {
  score: number
  user: User
}
