// src/hooks/useGroupWorkoutQueries.ts
import { useQuery, gql, ApolloError } from '@apollo/client';
import { GroupWorkout, GroupWorkoutTrack, GroupWorkoutTrackRound, DownloadLocation } from 'interfaces/group-workout';
import { useGroupWorkoutActions, useGroupWorkoutById, useCurrentGroupWorkout } from 'store/group-workout/selectors';
import { downloadFile, downloadAndAssign } from 'lib/download-file';
import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash-es';

// ============== QUERIES ==============
const GET_GROUP_WORKOUTS = gql`
  query GetGroupWorkouts {
    groupWorkout {
      id
      name
      duration
      description
      thumbnailFile {
        id
        originalName
        downloadLocation
      }
      audioFile {
        id
        originalName
        downloadLocation
      }
      groupWorkoutTracks {
        id
        name
        sortOrder
        duration
        isPreWorkout
        groupWorkoutTrackRounds {
          id
          name
          sortOrder
          duration
          calculatedAudioTime
          videoFile {
            id
            originalName
            downloadLocation
          }
          hasQuote
          quote
          isSwitchRound
          totalSwitchRounds
          timeInSwitchRound
          showIntensity
          showHitScore
          showGroupChallenge
          showGroupLeaderboard
        }
      }
    }
  }
`;

const GET_GROUP_WORKOUT = gql`
  query GetGroupWorkout($id: String!) {
    groupWorkout(input: { id: $id }) {
      id
      name
      duration
      description
      thumbnailFile {
        id
        originalName
        downloadLocation
      }
      audioFile {
        id
        originalName
        downloadLocation
      }
      groupWorkoutTracks {
        id
        name
        sortOrder
        duration
        isPreWorkout
        groupWorkoutTrackRounds {
          id
          name
          sortOrder
          duration
          calculatedAudioTime
          videoFile {
            id
            originalName
            downloadLocation
          }
          hasQuote
          quote
          isSwitchRound
          totalSwitchRounds
          timeInSwitchRound
          showIntensity
          showHitScore
          showGroupChallenge
          showGroupLeaderboard
        }
      }
    }
  }
`;


export const useGroupWorkoutsQuery = (): void => {
  const { setGroupWorkouts, setLoading, setError } = useGroupWorkoutActions();

  const { data, loading, error } = useQuery<{ groupWorkout: GroupWorkout[] }>(GET_GROUP_WORKOUTS, {
    onCompleted: (data) => {
      // Define an asynchronous function to process group workouts
      const processGroupWorkouts = async () => {
        try {
          // Use Promise.all with map to handle asynchronous downloads concurrently
          const updatedGroupWorkouts = await Promise.all(
            data.groupWorkout.map(async (groupWorkout) => {
              console.log('Processing groupWorkout:', groupWorkout);

              const downloadLocation = groupWorkout.thumbnailFile?.downloadLocation;

              if (downloadLocation) {
                try {
                  const downloadedFile = await downloadFile(downloadLocation);
                  if (downloadedFile) {
                    console.log('Downloaded file blobUrl:', downloadedFile.blobUrl);

                    // Create a new thumbnailFile object with the blobUrl
                    const updatedThumbnailFile = {
                      ...groupWorkout.thumbnailFile,
                      blobUrl: downloadedFile.blobUrl,
                    };

                    // Return a new groupWorkout object with the updated thumbnailFile
                    return {
                      ...groupWorkout,
                      thumbnailFile: updatedThumbnailFile,
                    };
                  }
                } catch (downloadError) {
                  console.error('Error downloading file:', downloadError);
                  // Optionally, you can choose to return the original groupWorkout or handle it differently
                }
              }

              // If there's no downloadLocation or download failed, return the original groupWorkout
              return groupWorkout;
            })
          );

          // Update the state with the new groupWorkouts array
          setGroupWorkouts(updatedGroupWorkouts);
        } catch (processingError) {
          console.error('Error processing group workouts:', processingError);
          setError('Failed to process group workouts.');
        } finally {
          setLoading(false);
        }
      };

      // Invoke the asynchronous processing function
      processGroupWorkouts();
    },
    onError: (error: ApolloError) => {
      console.error('Error fetching group workouts:', error.message);
      setError(error.message);
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(loading);
    if (error) {
      setError(error.message);
    }
    // Only set group workouts if data changes and it's not handled in onCompleted
    // However, since we're handling it in onCompleted, you might not need to set it here
  }, [loading, error, setLoading, setError]);
};

/**
 * Custom hook to fetch a single group workout by ID.
 * Upon successful fetch, it updates the GroupWorkoutStore.
 * Manages loading and error states via Zustand.
 */
export const useGroupWorkoutByIdQuery = (
  id: string,
  options?: { skip?: boolean }
) => {
  // console.log('useGroupWorkoutByIdQuery', id);

  const { setGroupWorkoutById, setLoading, setError } = useGroupWorkoutActions();
  const existingWorkout = useGroupWorkoutById(id); // Ensure this Hook is correctly defined
  // console.log('existing', existingWorkout);
  // console.log('skip', !id || !!existingWorkout || options?.skip);
  const currentWorkout = useCurrentGroupWorkout();
  // console.log('current', currentWorkout);
  if (existingWorkout && (!currentWorkout || currentWorkout.id != existingWorkout.id)) {
    // console.log("setting current group workout")
    setGroupWorkoutById(existingWorkout);
  }
  // Initiate side effects
  const { data, loading, error } = useQuery<{ groupWorkout: GroupWorkout[] }>(GET_GROUP_WORKOUT, {
    variables: { id },
    onCompleted: (data) => {
      if (data?.groupWorkout && data.groupWorkout.length > 0) {
        console.log(data)
        setGroupWorkoutById(data.groupWorkout[0]);
      }
      setLoading(false);
    },
    onError: (error: ApolloError) => {
      console.error(`Error fetching group workout with ID ${id}:`, error.message);
      setError(error.message);
      setLoading(false);
    },
    skip: !id || !!existingWorkout || options?.skip, // Skip the query if no ID is provided or the workout is already loaded.
  });

  // Ref to store the current workout ID at the time of download initiation
  const currentWorkoutIdRef = useRef<string | null>(null);

  useEffect(() => {
    if (currentWorkout) {
      // Store the current workout ID
      currentWorkoutIdRef.current = currentWorkout.id;

      // Trigger the download asynchronously

      downloadFilesGroupWorkout(currentWorkout, currentWorkout.id)
        .then((updatedWorkout) => {
          // updatedWorkout.groupWorkoutTracks.sort((a, b) => a.order - b.order);
          // updatedWorkout.groupWorkoutTracks = updatedWorkout.groupWorkoutTracks.map(track => ({
          //   ...track,
          //   groupWorkoutTrackRounds: [...(track.groupWorkoutTrackRounds ?? [])].sort(
          //     (r1, r2) => r1.order - r2.order
          //   ),
          // }));
          if (!isEqual(updatedWorkout, currentWorkout)) {
            console.log('download complete updating workout')
            // Verify if the workout is still current before updating
            if (currentWorkoutIdRef.current === updatedWorkout.id) {
              console.log(currentWorkoutIdRef.current)
              setGroupWorkoutById(updatedWorkout);
              console.log(updatedWorkout)
            } else {
              console.warn(`Workout ${updatedWorkout.id} is no longer the current workout. Skipping update.`);
            }
          }
          else {
            console.log('is equal');
          }
        })
        .catch((downloadError) => {
          console.error('Error downloading workout files:', downloadError);
          setError('Failed to download workout files.');
        });
    }
  }, [currentWorkout, setGroupWorkoutById, setError]);

  useEffect(() => {
    setLoading(loading);
    if (error) {
      setError(error.message);
    }
  }, [loading, error, setLoading, setError]);

  return null; // This hook doesn't need to return anything
};


/**
 * Downloads all necessary files for a given GroupWorkout asynchronously.
 *
 * @param groupWorkout - The GroupWorkout object to process.
 * @param currentWorkoutId - The ID of the current workout to ensure consistency.
 * @returns A Promise that resolves to the updated GroupWorkout.
 */
export const downloadFilesGroupWorkout = async (
  groupWorkout: GroupWorkout,
  currentWorkoutId: string
): Promise<GroupWorkout> => {
  try {
    // Check if the workout is still the current one before proceeding
    if (groupWorkout.id !== currentWorkoutId) {
      console.warn(`Workout ${groupWorkout.id} is no longer the current workout. Aborting download.`);
      return groupWorkout;
    }

    // Process each GroupWorkoutTrack concurrently
    const updatedGroupWorkoutTracks: GroupWorkoutTrack[] = await Promise.all(
      groupWorkout.groupWorkoutTracks.map(async (track) => {
        // Process each GroupWorkoutTrackRound within the track
        const updatedTrackRounds: GroupWorkoutTrackRound[] = await Promise.all(
          track.groupWorkoutTrackRounds.map(async (round) => {
            // If videoFile exists, download and assign blobUrl
            if (round.videoFile) {
              const updatedVideoFile = await downloadAndAssign<DownloadLocation, 'downloadLocation', 'blobUrl'>(
                round.videoFile,
                'downloadLocation',
                'blobUrl',
                'video'
              );

              return {
                ...round,
                videoFile: updatedVideoFile,
              };
            }

            return round;
          })
        );

        return {
          ...track,
          groupWorkoutTrackRounds: updatedTrackRounds,
        };
      })
    );

    // Download and assign blobUrl for audioFile if it exists
    let updatedAudioFile: DownloadLocation | null = null;
    if (groupWorkout.audioFile) {
      updatedAudioFile = await downloadAndAssign<DownloadLocation, 'downloadLocation', 'blobUrl'>(
        groupWorkout.audioFile,
        'downloadLocation',
        'blobUrl',
        'audio'
      );
    }

    return {
      ...groupWorkout,
      audioFile: updatedAudioFile,
      groupWorkoutTracks: updatedGroupWorkoutTracks,
    };
  } catch (error) {
    console.error('Error processing GroupWorkout:', error);
    throw error;
  }
};
