import React from 'react';
import Main from 'layout/main';
import Header from 'components/header/header';
import EditGroupWorkoutForm from 'components/group-workout/edit-group-workout-form'
import { useParams } from 'react-router-dom'

const EditGroupWorkoutPage = () => {
  const { id } = useParams<string>();

  return (
    <Main className="nextround">
      <div className="editGroupWorkoutPage">
        <Header title={<h1>Edit Group Workout</h1>} />
        <EditGroupWorkoutForm groupWorkoutId={id!} />
      </div>
    </Main>
  );
};

export default EditGroupWorkoutPage;
