import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { useState, useCallback } from 'react'

const CREATE_FILE = gql`
  mutation CreateFile($input: FileInputSchema!) {
    createFile(input: $input) {
      id
    }
  }
`;

const GET_FILE_UPLOAD_LOCATION = gql`
  query getFileUploadLocation($FileInput: [FileInputSchema!]!) {
    file(input: $FileInput) {
      id
      uploadLocation
    }
  }
`;

const GET_FILE_DOWNLOAD_LOCATION = gql`
  query getFileDownloadLocation($FileInput: [FileInputSchema!]!) {
    file(input: $FileInput) {
      id
      originalName
      downloadLocation
    }
  }
`;

const useFileUpload = () => {
  const [error, setError] = useState<string>('');
  
  const [createFile] = useMutation(CREATE_FILE, {
    onError: (err) => {
      setError(err.message);
    }
  });
  
  const [getFileUploadLocation] = useLazyQuery(GET_FILE_UPLOAD_LOCATION, {
    onError: (err) => {
      setError(err.message);
    }
  });
  
  const [getFileDownloadLocation] = useLazyQuery(GET_FILE_DOWNLOAD_LOCATION, {
    onError: (err) => {
      setError(err.message);
    }
  });

  const uploadFile = useCallback(async (file: File) => {
    setError('');
    try {
      // 1. Create file record
      const fileResp = await createFile({ variables: { input: { originalName: file.name } } });
      const newFileId = fileResp.data.createFile.id;

      // 2. Get upload location
      const uploadLocResp = await getFileUploadLocation({ variables: { FileInput: [{ id: newFileId }] } });
      const uploadLocation = uploadLocResp.data?.file?.[0]?.uploadLocation;
      if (!uploadLocation) {
        throw new Error('No uploadLocation returned from server');
      }

      // 3. Upload file via REST
      const formData = new FormData();
      formData.append('file', file);

      const token = localStorage.getItem('NEXTROUND_API');
      const uploadResponse = await fetch(uploadLocation, {
        method: 'POST',
        headers: {
          'Authorization': token ? token : '',
          'X-Authorization-Method': 'api',
        },
        body: formData,
      });

      if (!uploadResponse.ok) {
        throw new Error(`File upload failed with status ${uploadResponse.status}`);
      }

      // 4. (Optional) Get download location
      const downloadLocResp = await getFileDownloadLocation({ variables: { FileInput: [{ id: newFileId }] } });
      const downloadLocation = downloadLocResp.data?.file?.[0]?.downloadLocation;

      return {
        fileId: newFileId,
        downloadLocation,
      };
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        // Handle other kinds of thrown values, e.g. strings or objects
        setError(String(err));
      }
      console.error(err);
      return null;
    }
  }, [createFile, getFileUploadLocation, getFileDownloadLocation]);

  return {
    error,
    uploadFile,
  };
};

export default useFileUpload;
