import React, { useId } from "react";
import "./half-circle-progress.scss";

interface HalfCircleProgressProps {
  value: number; // Value between 1 and maxValue
  maxValue?: number; // Maximum value, default is 7
  arrowSize?: number; // Size of the arrow
  arrowDistance?: number; // Distance of the arrow from the center
  gradientStart?: string; // Start color of the gradient
  gradientEnd?: string; // End color of the gradient
  uniqueId?: string; // Unique ID to differentiate gradients
}

const HalfCircleProgress: React.FC<HalfCircleProgressProps> = ({
  value,
  maxValue = 7,
  arrowSize = 30,
  arrowDistance = -10,
  gradientStart = "#20ebec",
  gradientEnd = "#ffbd2c",
  uniqueId,
}) => {
  const generatedId = useId(); // Generate a unique ID using React's useId
  const gradientId = uniqueId || `half-circle-gradient-${generatedId}`;
  const halfCircleSize = 140; // Adjust to prevent cutting
  const strokeWidth = 8;
  const radius = halfCircleSize / 2 - strokeWidth;
  const circumference = Math.PI * radius; // Half-circle circumference

  const numberCircleRadius = 25; // Radius of the number circle

  // Calculate the stroke offset for the progress circle
  const calculateStrokeOffset = () => {
    const progress = Math.min(value / maxValue, 1); // Ensure progress is clamped between 0 and 1
    return circumference * (1 - progress); // Invert because offset starts from the beginning
  };

  // Calculate the arrow position and rotation angle
  const calculateArrowTransform = () => {
    const angle = (Math.PI * value) / maxValue; // Convert value to radians
    const x = halfCircleSize / 2 + (numberCircleRadius + arrowDistance + arrowSize / 2) * Math.cos(angle - Math.PI);
    const y = radius + strokeWidth + (numberCircleRadius + arrowDistance + arrowSize / 2) * Math.sin(angle - Math.PI);
    const rotation = (angle * 180) / Math.PI - 90; // Convert radians to degrees and adjust
    return { x, y, rotation };
  };

  const arrowTransform = calculateArrowTransform();

  return (
    <div
      className="half-circle-progress"
      style={{ width: halfCircleSize, height: halfCircleSize / 2 }}
    >
      <svg
        width={halfCircleSize}
        height={halfCircleSize / 2 + 10} // Adjust height for top spacing
        viewBox={`0 0 ${halfCircleSize} ${halfCircleSize / 2 + 10}`}
      >
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={gradientStart} />
            <stop offset="100%" stopColor={gradientEnd} />
          </linearGradient>
        </defs>
        {/* Background Circle */}
        <path
          d={`M ${strokeWidth},${radius + strokeWidth} 
              A ${radius},${radius} 0 0,1 ${halfCircleSize - strokeWidth},${radius + strokeWidth}`}
          stroke="#2c2c2c"
          strokeWidth={strokeWidth + 4}
          fill="none"
        />
        {/* Progress Circle */}
        <path
          d={`M ${strokeWidth},${radius + strokeWidth} 
              A ${radius},${radius} 0 0,1 ${halfCircleSize - strokeWidth},${radius + strokeWidth}`}
          stroke={`url(#${gradientId})`}
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={calculateStrokeOffset()}
        />
      </svg>
      {/* Arrow pointing to the value */}
      <div
        className="arrow"
        style={{
          position: "absolute",
          top: arrowTransform.y,
          left: arrowTransform.x,
          transform: `translate(-50%, -50%) rotate(${arrowTransform.rotation}deg)`,
          width: `${arrowSize}px`,
          height: `${arrowSize}px`,
        }}
      >
        <svg
          width={arrowSize}
          height={(arrowSize / 22) * 16} // Maintain aspect ratio
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.9844 16L12.9009 1.043C12.0595 -0.347665 9.94051 -0.347665 9.09915 1.043L0 16C3.34986 14.491 7.05807 13.5737 11 13.5737C14.9419 13.5737 18.6501 14.491 22 16H21.9844Z"
            fill="black"
          />
        </svg>
      </div>
      {/* Number Circle */}
      <div
        className="number-circle"
        style={{
          position: "absolute",
        }}
      >
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="22"
            cy="22"
            r="21"
            fill="#1E1E1E" // Dark background
            stroke="white" // White border
            strokeWidth="2"
          />
        </svg>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );
};

export default HalfCircleProgress;
