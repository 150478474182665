import { useNavigate } from 'react-router-dom'
import ButtonRow from './button-row'
import { GroupWorkout } from 'interfaces/group-workout'
import './style.scss'
import BoxingBag from './bag'
import { useEffect, useRef, useState } from 'react'
import DynamicLink from './link'
import { useGroupWorkoutByIdQuery } from 'api/queries/group-workouts'
import { useCurrentGroupWorkout, useGroupWorkoutError, useGroupWorkoutLoading } from 'store/group-workout/selectors'
import { useCurrentGroupWorkoutSession, useGroupWorkoutSessionActions } from 'store/group-workout-session/selectors'
import { useCreateGroupWorkoutSession } from 'api/mutations/group-workout-session'
import LoadingSpinner from 'components/group-workout/loading-spinner'

const colors: string[] = [
  '#FF7733',
  '#F7D126',
  '#F726D1',
  '#F0F726',
  '#F04D26',
  '#7E68AE',
  '#7857FF',
  '#75C7FF',
  '#70ED99',
  '#00AFB9',
]

interface PopupProps {
  groupWorkoutProp: GroupWorkout | undefined
  closePopup: () => void
}

const GroupSetup: React.FC<PopupProps> = ({ groupWorkoutProp, closePopup }) => {
  const [connectedLink, setConnectedLink] = useState<string[]>([])

  const navigate = useNavigate()
  useGroupWorkoutByIdQuery(groupWorkoutProp?.id || '')
  console.log("group workout id", groupWorkoutProp?.id)
  // Access loading and error states from Zustand store via selectors
  const loading = useGroupWorkoutLoading()
  const error = useGroupWorkoutError()
  const hasSessionBeenCreated = useRef(false)
  
  // Access groupWorkouts data from Zustand store via selectors
  const groupWorkout = useCurrentGroupWorkout()
  const groupWorkoutSession = useCurrentGroupWorkoutSession()
  const { createGroupWorkoutSession } = useCreateGroupWorkoutSession()
  const { toggleGroupBagEnabled } = useGroupWorkoutSessionActions()
  const handleCreateSession = async () => {
    try {
      if (groupWorkout) {
        await createGroupWorkoutSession(groupWorkout.id)
      }
    } catch (e) {
      console.error(e)
    }
  }


  useEffect(() => {
    if (!loading && groupWorkout && !hasSessionBeenCreated.current) {
      hasSessionBeenCreated.current = true
      handleCreateSession()
    }
  })

  const startPreWorkout = () => {
    if (groupWorkoutSession?.id) {
      navigate(`/group-workout/pre-workout/${groupWorkoutSession?.id}`, { state: { id: groupWorkoutSession?.id } })
    }
  }

  return (
    <div className="popupBackground">
      <div className="popupWrapper setup-container" style={{maxWidth: '80vw', width: 'fit-content'}}>
        {groupWorkoutProp && (
          <div>
            <div className="group-setup-duration">
              {groupWorkoutProp.duration} {'min.'.toUpperCase()}
            </div>
            <div className="group-setup-title" style={{ textAlign: 'center' }}>
              {groupWorkoutProp.name}
            </div>
            <div className="group-setup-subtitle" style={{ textAlign: 'center' }}>
              {groupWorkoutProp.description}
            </div>
          </div>
        )}

        {!groupWorkoutSession ? (
          <div style={{ padding: '2rem 0' }}>
            <LoadingSpinner />
          </div>
        ) : (
          <div style={{ display: 'flex', gap: '1rem', padding: '2rem 0', maxWidth: '80vw', width: 'fit-content', overflow: 'scroll' }}>
            {groupWorkoutSession.groupBags.map((bag, index) => {
              return (
                <div key={bag.id + 'group'}>
                  <div onClick={() => toggleGroupBagEnabled(bag.id)} key={bag.id + 'container'}>
                    <BoxingBag key={bag.id + 'bag'} color={colors[index]} opacity={bag.isEnabled ? 1 : 0.4} />
                  </div>
                  <div
                    onClick={() =>
                      setConnectedLink((prev) => {
                        const newConnected = [...prev]
                        newConnected[index] = newConnected[index] ? '' : 'connected'
                        return newConnected
                      })
                    }
                    style={{
                      display: 'flex',
                      justifySelf: 'center',
                      WebkitJustifyContent: 'center',
                      maxHeight: '1rem',
                      minHeight: '1rem',
                    }}
                  >
                    <DynamicLink key={index + 'link'} connected={connectedLink[index] == 'connected'} />
                  </div>
                </div>
              )
            })}
          </div>
        )}

        <ButtonRow
          close="Back"
          closePopup={closePopup}
          start="Start Training"
          handleStartTraining={() => startPreWorkout()}
        />
      </div>
    </div>
  )
}

export default GroupSetup
