import {
  INRWorkoutSession,
  INRWorkoutSessionExercise,
  INRWorkoutSessionHit,
  INRWorkoutSessionRound,
} from 'interfaces/workout'
import { getMax2Decimal } from './utilities'

// These functions should be aligned with the personal app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps

// Intensity is calculated based on the force of the hits over time, along with the user's weight (heavy users might have an easier time reaching higher force)
// The user's weight can be queried. The default weight is 75 kg.
// Intensity results are categorized into zones, from 1 to 7.
// intensity is calculated over the last 10 seconds
// during the first 10 seconds of the exercise, the intensity is calculated based on the time passed, to average a value that is comparable to the rest of the exercise
// first second = value * 10
// second second = value * 5
// third second = value * 3.33
// et cetera

const MAX_INTENSITY_TIME = 10
const getIntensityValueForExerciseSecond = (value: number, exerciseSecond: number) => {
  if (exerciseSecond >= MAX_INTENSITY_TIME) return value

  const multiplier = MAX_INTENSITY_TIME / exerciseSecond
  return value * multiplier
}

const evaluateIntensity = (intensity: number = 1) => {
  const n = getMax2Decimal(intensity)
  let zone: number = 1

  const zone2Start = 2
  const zone3Start = 4.75
  const zone4Start = 8.25
  const zone5Start = 13.25
  const zone6Start = 20.5
  const zone7Start = 33.25

  const checkZone = (zoneStart: number, zoneEnd: number) => n >= zoneStart && n < zoneEnd

  switch (true) {
    case checkZone(zone2Start, zone3Start):
      zone = 2
      break
    case checkZone(zone3Start, zone4Start):
      zone = 3
      break
    case checkZone(zone4Start, zone5Start):
      zone = 4
      break
    case checkZone(zone5Start, zone6Start):
      zone = 5
      break
    case checkZone(zone6Start, zone7Start):
      zone = 6
      break
    case n >= zone7Start:
      zone = 7
      break
    default:
      zone = 1
      break
  }

  return zone
}

export const calculateTotalIntensity = (filteredHits: INRWorkoutSessionHit[] = [], weight: number) => {
  const intensityPerHit = filteredHits?.map((hit) => hit.force / weight)
  const totalIntensity = intensityPerHit.reduce((a, b) => a + b, 0)
  return totalIntensity
}

export const getEvaluatedIntensity = (
  hitsLastTenSeconds: INRWorkoutSessionHit[],
  weight: number = 75,
  exerciseSecond: number
) => {
  const totalIntensity = calculateTotalIntensity(hitsLastTenSeconds, weight)
  const intensityForExerciseSecond = getIntensityValueForExerciseSecond(totalIntensity, exerciseSecond)
  const evaluatedIntensity = evaluateIntensity(intensityForExerciseSecond)
  return evaluatedIntensity
}

export const getHitsLastTenSeconds = (hits: INRWorkoutSessionHit[], timestamp: number, seconds: number = 10000) => {
  return hits.filter((hit) => {
    const timeDifference = timestamp - Number(hit.punchedAt)
    return timeDifference <= seconds && timeDifference >= 0
  })
}

export const getEvaluatedExerciseIntensity = (exercise: INRWorkoutSessionExercise, weight: number | null): number[] => {
  const effectiveWeight = getEffectiveWeight(weight)
  const intensityHits = getIntensityHitsExercise(exercise)
  return intensityHits.map((hits, i) => getEvaluatedIntensity(hits, effectiveWeight, i + 1))
}

export const getEvaluatedRoundIntensity = (round: INRWorkoutSessionRound, weight: number | null): number[] => {
  const effectiveWeight = getEffectiveWeight(weight)
  const intensityPerExercise = round.exercises.flatMap((exercise) =>
    getEvaluatedExerciseIntensity(exercise, effectiveWeight)
  )
  return intensityPerExercise
}

export const getEvaluatedWorkoutIntensity = (workout: INRWorkoutSession, weight: number): number[] => {
  const effectiveWeight = getEffectiveWeight(weight)
  const intensityPerRound = workout.rounds.flatMap((round) => getEvaluatedRoundIntensity(round, effectiveWeight))
  return intensityPerRound
}

const getEffectiveWeight = (weight: number | null, defaultWeight: number = 75): number => weight ?? defaultWeight

export const getIntensityHitsExercise = ({ started, seconds, hits }: INRWorkoutSessionExercise) => {
  const startTime = started ? new Date(started) : new Date()
  const intensityHits: INRWorkoutSessionHit[][] = []

  for (let i = 1; i < seconds + 1; i += 1) {
    const second = startTime.getTime() + i * 1000
    const hitsLastTenSecond = getHitsLastTenSeconds(hits, second)
    intensityHits.push(hitsLastTenSecond)
  }

  return intensityHits
}
