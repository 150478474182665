import React from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'

const WorkoutGrid: React.FC = () => {
  const navigate = useNavigate()

    const handleClick = () => {
        navigate('/group-workouts')
    }

  return (
    <div className="grid-container-home">
      <div className="grid-item one not-clickable">
        <div>NEXTROUND</div>
        <div>DEMO</div>
      </div>
      <div className="grid-item two" onClick={handleClick}>GROUP SESSION</div>
      <div className="grid-item three not-clickable">GAMES</div>
      <div className="grid-item four not-clickable">HIT TO START</div>
      <div className="grid-item five not-clickable">
        <div>WORKOUTS &</div>
        <div>CHALLENGES</div>
      </div>
      <div className="grid-item six not-clickable">CUSTOM</div>
      <div className="grid-item seven not-clickable">WARMING UP</div>
    </div>
  )
}

export default WorkoutGrid
