import React, { useState, useEffect, useRef } from 'react';
import { gql, useMutation, useLazyQuery, useQuery, ApolloError } from '@apollo/client';
import AudioDownloaderPlayer, { AudioDownloaderPlayerHandle } from './audio-downloader-player';
import VideoDownloaderPlayer from './video-downloader-player';
import ImageDownloaderViewer from './image-downloader-viewer';
import './index.scss';
import HorizontalTabs from './horizontal-tabs';
import useFileUpload  from 'api/mutations/file'

// GraphQL Queries and Mutations

const GET_GROUP_WORKOUT = gql`
  query GetGroupWorkout($id: String!) {
    groupWorkout(input: { id: $id }) {
      id
      name
      duration
      description
      audioFile {
        id
        originalName
        downloadLocation
      }
      thumbnailFile {
        id
        originalName
        downloadLocation
      }
      groupWorkoutTracks {
        id
        name
        sortOrder
        duration
        isPreWorkout
        groupWorkoutTrackRounds {
          id
          name
          sortOrder
          duration
          calculatedAudioTime
          videoFile {
            id
            originalName
            downloadLocation
          }
          hasQuote
          quote
          isSwitchRound
          totalSwitchRounds
          timeInSwitchRound
          showIntensity
          showHitScore
          showGroupChallenge
          showGroupLeaderboard
        }
      }
    }
  }
`;

const UPDATE_GROUP_WORKOUT = gql`
  mutation customUpdateGroupWorkout($input: GroupWorkoutInputSchema!) {
    customUpdateGroupWorkout(input: $input) {
      id
      name
      duration
      description
      audioFile {
        id
        originalName
        downloadLocation
      }
      thumbnailFile {
        id
        originalName
        downloadLocation
      }
      groupWorkoutTracks {
        id
        name
        sortOrder
        duration
        isPreWorkout
        groupWorkoutTrackRounds {
          id
          name
          sortOrder
          duration
          calculatedAudioTime
          videoFile {
            id
            originalName
            downloadLocation
          }
          hasQuote
          quote
          isSwitchRound
          totalSwitchRounds
          timeInSwitchRound
          showIntensity
          showHitScore
          showGroupChallenge
          showGroupLeaderboard
        }
      }
    }
  }
`;

const DELETE_GROUP_WORKOUT = gql`
  mutation deleteGroupWorkout($input: String!) {
    deleteGroupWorkout(input: $input)
  }
`;

// TypeScript Interfaces

interface Round {
  id?: string;
  name: string;
  sortOrder: number;
  duration: number;
  calculatedAudioTime: number;
  videoFile?: {
    id: string;
    originalName?: string;
    downloadLocation?: string;
  } | null;
  hasQuote: boolean;
  quote: string;
  isSwitchRound: boolean;
  totalSwitchRounds: number;
  timeInSwitchRound: number;
  showIntensity: boolean;
  showHitScore: boolean;
  showGroupChallenge: boolean;
  showGroupLeaderboard: boolean;
}
interface Track {
  id?: string;
  name: string;
  sortOrder: number;
  duration: number;
  rounds: Round[];
  isPreWorkout: boolean;
}

interface EditGroupWorkoutFormProps {
  groupWorkoutId: string;
}

function EditGroupWorkoutForm({ groupWorkoutId }: EditGroupWorkoutFormProps) {
  const { data: groupWorkoutData, loading: workoutLoading, error: workoutError } = useQuery(GET_GROUP_WORKOUT, {
    variables: { id: groupWorkoutId },
  });
  const [name, setName] = useState<string>('');
  const [duration, setDuration] = useState<number>(0);
  const [description, setDescription] = useState<string>('');
  const [audioFileId, setAudioFileId] = useState<string | null>(null);
  const [finalAudioDownloadLocation, setFinalAudioDownloadLocation] = useState<string | null>(null);
  const [thumbnailFileId, setThumbnailFileId] = useState<string | null>(null);
  const [finalThumbnailDownloadLocation, setFinalThumbnailDownloadLocation] = useState<string | null>(null);
  const [tracks, setTracks] = useState<Track[]>([]);
  const [updateGroupWorkout, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_GROUP_WORKOUT);
  const [deleteGroupWorkout, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_GROUP_WORKOUT);
  const { uploadFile } = useFileUpload();
  const audioPlayerRef = useRef<AudioDownloaderPlayerHandle | null>(null);

  useEffect(() => {
    if (groupWorkoutData?.groupWorkout?.[0]) {
      const workout = groupWorkoutData.groupWorkout[0];
      setName(workout.name || '');
      setDuration(workout.duration || 0);
      setDescription(workout.description || '');
      setFinalThumbnailDownloadLocation(workout.thumbnailFile?.downloadLocation || null);
      setThumbnailFileId(workout.thumbnailFile?.id || null);
      setFinalAudioDownloadLocation(workout.audioFile?.downloadLocation || null);
      setAudioFileId(workout.audioFile?.id || null);
      
      const fetchedTracks = workout.groupWorkoutTracks || [];
      setTracks(
        [...fetchedTracks]
          .sort((trackA, trackB) => trackA.sortOrder - trackB.sortOrder)
          .map(track => ({
            id: track.id,
            name: track.name,
            sortOrder: track.sortOrder,
            duration: track.duration,
            isPreWorkout: track.isPreWorkout,
            rounds: 
              [...(track.groupWorkoutTrackRounds || [])]
                .sort((roundA, roundB) => roundA.sortOrder - roundB.sortOrder)
                .map(round => ({
                  id: round.id,
                  name: round.name,
                  sortOrder: round.sortOrder,
                  duration: round.duration,
                  calculatedAudioTime: round.calculatedAudioTime,
                  videoFile: round.videoFile
                    ? {
                        id: round.videoFile.id,
                        originalName: round.videoFile.originalName,
                        downloadLocation: round.videoFile.downloadLocation,
                      }
                    : null,
                  hasQuote: round.hasQuote || false,
                  quote: round.quote,
                  isSwitchRound: round.isSwitchRound || false,
                  totalSwitchRounds: round.totalSwitchRounds || 0,
                  timeInSwitchRound: round.timeInSwitchRound || 15,
                  showIntensity: round.showIntensity || false,
                  showHitScore: round.showHitScore || false,
                  showGroupChallenge: round.showGroupChallenge || false,
                  showGroupLeaderboard: round.showGroupLeaderboard || false,
                })),
          }))
      );
    }
  }, [groupWorkoutData]);

  const confirmDeleteGroupWorkout = async () => {
    const confirmed = window.confirm('Are you sure you want to delete this group workout?');
    if (confirmed) {
      try {
        const { data } = await deleteGroupWorkout({
          variables: {
            input: groupWorkoutId,
          },
        });
  
        if (data.deleteGroupWorkout) {
          alert('Group workout deleted successfully!');
          // Redirect or update UI after deletion
          window.location.href = '/group-workouts'; // Adjust the URL as needed
        } else {
          alert('Failed to delete the group workout. Please try again.');
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          console.error('ApolloError:', error.graphQLErrors, error.networkError);
          alert(`Failed to delete group workout: ${error.message}`);
        } else {
          console.error('Unexpected error:', error);
          alert('An unexpected error occurred.');
        }
      }
    }
  };

  const deleteTrack = (trackIndex: number) => {
    const confirmed = window.confirm('Are you sure you want to delete this track?')
    if (confirmed) {
      const updatedTracks = tracks.filter((_, index) => index !== trackIndex)
      setTracks(updatedTracks)
    }
  };
  
  const deleteRound = (trackIndex: number, roundIndex: number) => {
    const confirmed = window.confirm('Are you sure you want to delete this round?');
    if (confirmed) {
      const updatedTracks = [...tracks];
      updatedTracks[trackIndex].rounds = updatedTracks[trackIndex].rounds.filter(
        (_, index) => index !== roundIndex
      );
      setTracks(updatedTracks);
    }
  };

  const deleteVideoFile = (trackIndex: number, roundIndex: number) => {
    const confirmed = window.confirm('Are you sure you want to delete this video?');
    if (confirmed) {
      const updatedTracks = [...tracks];
      updatedTracks[trackIndex].rounds[roundIndex].videoFile = null;
      setTracks(updatedTracks);
    }
  };

  const addTrack = () => {
    const newTrack: Track = {
      name: `Track ${tracks.length + 1}`,
      sortOrder: tracks.length + 1,
      duration: 5,
      rounds: [],
      isPreWorkout: false,

    };
    setTracks([...tracks, newTrack]);
  };

  const addRound = (trackIndex: number) => {
    const updatedTracks = [...tracks];
    const newRound: Round = {
      name: `Round ${updatedTracks[trackIndex].rounds.length + 1}`,
      sortOrder: updatedTracks[trackIndex].rounds.length + 1,
      duration: 5,
      calculatedAudioTime: 0,
      hasQuote: false,
      quote: "",
      isSwitchRound: false,
      totalSwitchRounds: 0,
      timeInSwitchRound: 15,
      showIntensity: false,
      showHitScore: false,
      showGroupChallenge: false,
      showGroupLeaderboard: false,
    };
    updatedTracks[trackIndex].rounds.push(newRound);
    setTracks(updatedTracks);
  };

  const handleVideoFileChange = async (
    trackIndex: number,
    roundIndex: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      // Validate file type
      const allowedTypes = ['video/mp4', 'video/webm', 'video/ogg'];
      if (!allowedTypes.includes(selectedFile.type)) {
        alert('Please select a valid video file (MP4, WebM, OGG).');
        return;
      }
      try {
        const videoFileResult = await uploadFile(selectedFile);
        if (!videoFileResult) {
          alert('Video file upload failed.');
          return;
        }

        // Update round state
        const updatedTracks = [...tracks];

        updatedTracks[trackIndex].rounds[roundIndex].videoFile = {
          id: videoFileResult.fileId,
          originalName: selectedFile.name,
          downloadLocation: videoFileResult.downloadLocation,
        };

        setTracks(updatedTracks);
      } catch (error) {
        console.error('File upload error:', error);
        alert('There was an error uploading your file. Please try again.');
      }
    }
  };

  const handleAudioFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      // Validate file type
      const allowedTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg'];
      if (!allowedTypes.includes(selectedFile.type)) {
        alert('Please select a valid audio file (MP3, WAV, OGG).');
        return;
      }

      try {
        const audioFileResult = await uploadFile(selectedFile);
        if (!audioFileResult) {
          alert('Audio file upload failed.');
          return;
        }
        const downloadLocation = audioFileResult.downloadLocation;

        setFinalAudioDownloadLocation(downloadLocation);
        setAudioFileId(audioFileResult.fileId);
      } catch (error) {
        console.error('File upload error:', error);
        alert('There was an error uploading your file. Please try again.');
      }
    }
  };

  const handleThumbnailFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      // Validate file type
      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(selectedFile.type)) {
        alert('Please select a valid image file (JPEG, PNG).');
        return;
      }

      try {
        const imageFileResult = await uploadFile(selectedFile);
        if (!imageFileResult) {
          alert('Image file upload failed.');
          return;
        }
        const downloadLocation = imageFileResult.downloadLocation;

        setFinalThumbnailDownloadLocation(downloadLocation);
        setThumbnailFileId(imageFileResult.fileId);
      } catch (error) {
        console.error('File upload error:', error);
        alert('There was an error uploading your file. Please try again.');
      }
    }
  };

  const handleTrackChange = (index: number, field: keyof Track, value: string | number | boolean) => {
    const updatedTracks = [...tracks];
    if (typeof value === 'boolean') {
      updatedTracks[index][field] = value as never;
    }
    else {
      if (field === 'duration' || field === 'sortOrder') {
        if (typeof value === 'string')
        {
          const parsedValue = parseInt(value, 10)
          if (!isNaN(parsedValue)) {
            updatedTracks[index][field] = parsedValue;
          }
        }
        else {
          updatedTracks[index][field] = value as never;
        }
      } else {
        updatedTracks[index][field] = value as never;
      }
    }
    setTracks(updatedTracks);
  };

  const sortEverything = () => {
    tracks.sort((a, b) => a.sortOrder - b.sortOrder)
    setTracks(tracks.map(track => ({
      ...track,
      rounds: [...(track.rounds ?? [])].sort(
        (r1, r2) => r1.sortOrder - r2.sortOrder
      ),
      }))
    )
  }

  const handleRoundChange = (
    trackIndex: number,
    roundIndex: number,
    field: keyof Round,
    value: string | number | boolean
  ) => {
    const updatedTracks = [...tracks];
  
    if (field === 'duration' || field === 'sortOrder' || field === 'totalSwitchRounds' || field === 'timeInSwitchRound' || field === 'calculatedAudioTime') {
      // Handle numeric fields
      const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value as never;
      if (!isNaN(parsedValue)) {
        updatedTracks[trackIndex].rounds[roundIndex][field] = parsedValue;
      }
    } else if (typeof value === 'boolean') {
      // Handle boolean fields
      updatedTracks[trackIndex].rounds[roundIndex][field] = value as never;
    } else {
      // Handle string fields
      updatedTracks[trackIndex].rounds[roundIndex][field] = value as never;
    }
    setTracks(updatedTracks);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    // Validate input fields
    if (!name.trim()) {
      alert('Name is required.');
      return;
    }
  
    if (isNaN(duration)) {
      alert('Duration must be a valid number.');
      return;
    }
  
    // Validate tracks and rounds
    for (const track of tracks) {
      if (!track.name.trim()) {
        alert('Each track must have a name.');
        return;
      }
      if (isNaN(track.duration)) {
        alert('Each track must have a valid duration.');
        return;
      }
      for (const round of track.rounds) {
        if (!round.name.trim()) {
          alert('Each round must have a name.');
          return;
        }
        if (isNaN(round.duration)) {
          alert('Each round must have a valid duration.');
          return;
        }
      }
    }
  
    try {
      // Prepare tracks for submission without the groupWorkout field
      const updatedTracks = tracks.map((track) => ({
        id: track.id,
        name: track.name,
        sortOrder: track.sortOrder,
        duration: track.duration,
        isPreWorkout: track.isPreWorkout,
        groupWorkoutTrackRounds: track.rounds.map((round) => ({
          id: round.id,
          name: round.name,
          sortOrder: round.sortOrder,
          duration: round.duration,
          calculatedAudioTime: round.calculatedAudioTime,
          videoFile: round.videoFile ? { id: round.videoFile.id } : null,
          hasQuote: round.hasQuote,
          quote: round.quote,
          isSwitchRound: round.isSwitchRound,
          totalSwitchRounds: round.totalSwitchRounds,
          timeInSwitchRound: round.timeInSwitchRound,
          showIntensity: round.showIntensity,
          showHitScore: round.showHitScore,
          showGroupChallenge: round.showGroupChallenge,
          showGroupLeaderboard: round.showGroupLeaderboard,
        })),
      }));
      // Send update mutation
      const response = await updateGroupWorkout({
        variables: {
          input: {
            id: groupWorkoutId,
            name,
            duration,
            description,
            thumbnailFile: thumbnailFileId ? {id: thumbnailFileId} : undefined,
            audioFile: audioFileId ? { id: audioFileId } : undefined,
            groupWorkoutTracks: updatedTracks,
          },
        },
      });
      alert('Group workout updated successfully!');
    } catch (error) {
      if (error instanceof ApolloError) {
        console.error('ApolloError:', error.graphQLErrors, error.networkError);
        alert(`Failed to update group workout: ${error.message}`);
      } else {
        console.error('Unexpected error:', error);
        alert('An unexpected error occurred.');
      }
    }
  };

  if (workoutLoading) return <p>Loading...</p>;
  if (workoutError) return <p>Error loading workout: {workoutError.message}</p>;

  const overviewTab = {
    label: 'Overview',
    content: (
      <div>
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          Duration:
          <input
            type="number"
            value={duration}
            onChange={(e) => setDuration(parseInt(e.target.value, 10))}
          />
        </label>
        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
        <label>
          Thumbnail File:
          <input
            type="file"
            accept="image/*"
            onChange={handleThumbnailFileChange}
          />
        </label>
        {finalThumbnailDownloadLocation && (
          <div className="groupWorkoutForm__audio">
            <ImageDownloaderViewer
              downloadLocation={finalThumbnailDownloadLocation}
              token={localStorage.getItem('NEXTROUND_API') || ''}
            />
          </div>
        )}
        <label>
          Audio File:
          <input
            type="file"
            accept="audio/*"
            onChange={handleAudioFileChange}
          />
        </label>
        <button
          type="button"
          onClick={confirmDeleteGroupWorkout}
          className="delete-button"
          disabled={deleteLoading} // Disable the button while loading
        >
          {deleteLoading ? 'Deleting...' : 'Delete Group Workout'}
        </button>
        {deleteError && <p className="error">Error: {deleteError.message}</p>}
      </div>
    ),
  };

  const tracksTabs = tracks.map((track, trackIndex) => ({
  label: track.name,
  content: (
    <div>
      <HorizontalTabs
        tabs={[
          {
            label: 'Overview',
            content: (
              <div>
                <label>
                  Track Name:
                  <input
                    type="text"
                    value={track.name}
                    onChange={(e) =>
                      handleTrackChange(trackIndex, 'name', e.target.value)
                    }
                  />
                </label>
                <label>
                  Order:
                  <input
                    type="number"
                    value={track.sortOrder}
                    onChange={(e) =>
                      handleTrackChange(trackIndex, 'sortOrder', parseInt(e.target.value, 10))
                    }
                  />
                </label>
                <label>
                  Duration:
                  <input
                    type="number"
                    value={track.duration}
                    onChange={(e) =>
                      handleTrackChange(trackIndex, 'duration', parseInt(e.target.value, 10))
                    }
                  />
                </label>
                <label>
                  Pre Workout:
                  <input
                    type="checkbox"
                    checked={track.isPreWorkout}
                    onChange={(e) =>
                      handleTrackChange(trackIndex, 'isPreWorkout', e.target.checked)
                    }
                  />
                </label>
                <button
                  type="button"
                  onClick={() => deleteTrack(trackIndex)}
                  className="delete-button"
                >
                  Delete Track
                </button>
              </div>
            ),
          },
          ...track.rounds.map((round, roundIndex) => ({
            label: round.name,
            content: (
              <div>
                <label>
                  Round Name:
                  <input
                    type="text"
                    value={round.name}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'name', e.target.value)
                    }
                  />
                </label>
                <label>
                  Order:
                  <input
                    type="number"
                    value={round.sortOrder}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'sortOrder', parseInt(e.target.value, 10))
                    }
                  />
                </label>
                <label>
                  Duration:
                  <input
                    type="number"
                    value={round.duration}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'duration', parseInt(e.target.value, 10))
                    }
                  />
                </label>
                <label>
                  TimeInAudio:
                  <input
                    type="number"
                    value={round.calculatedAudioTime}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'calculatedAudioTime', parseInt(e.target.value, 10))
                    }
                  />
                </label>
                <button
                  type="button"
                  onClick={() => {
                    if (audioPlayerRef.current) {
                      audioPlayerRef.current.setPlaybackTime(round.calculatedAudioTime);
                    }
                  }}
                >
                  Play from {round.calculatedAudioTime}s
                </button>
                <label>
                  Video File:
                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleVideoFileChange(trackIndex, roundIndex, e)}
                  />
                </label>
                {round.videoFile && round.videoFile.downloadLocation && (
                  <div>
                    <VideoDownloaderPlayer
                      downloadLocation={round.videoFile.downloadLocation}
                      token={localStorage.getItem('NEXTROUND_API') || ''}
                    />
                    <button
                      type="button"
                      className="delete-button"
                      onClick={() => deleteVideoFile(trackIndex, roundIndex)}
                    >
                      Delete Video
                    </button>
                </div>
                )}
                <label>
                  Has Quote:
                  <input
                    type="checkbox"
                    checked={round.hasQuote}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'hasQuote', e.target.checked)
                    }
                  />
                </label>
                <label>
                  Quote:
                  <input
                    type="text"
                    value={round.quote}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'quote', e.target.value)
                    }
                  />
                </label>
                <div className="inline-group">
                  <label>
                    Is Switch Round:
                    <input
                      type="checkbox"
                      checked={round.isSwitchRound}
                      onChange={(e) =>
                        handleRoundChange(trackIndex, roundIndex, 'isSwitchRound', e.target.checked)
                      }
                    />
                  </label>
                  <label>
                    Total Switch Rounds:
                    <input
                      type="number"
                      value={round.totalSwitchRounds}
                      onChange={(e) =>
                        handleRoundChange(trackIndex, roundIndex, 'totalSwitchRounds', parseInt(e.target.value, 10))
                      }
                    />
                  </label>
                  <label>
                    Time in Switch Round:
                    <input
                      type="number"
                      value={round.timeInSwitchRound}
                      onChange={(e) =>
                        handleRoundChange(trackIndex, roundIndex, 'timeInSwitchRound', parseInt(e.target.value, 10))
                      }
                    />
                  </label>
                </div>
                <label>
                  Show Intensity:
                  <input
                    type="checkbox"
                    checked={round.showIntensity}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'showIntensity', e.target.checked)
                    }
                  />
                </label>
                <label>
                  Show Hit Score:
                  <input
                    type="checkbox"
                    checked={round.showHitScore}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'showHitScore', e.target.checked)
                    }
                  />
                </label>
                <label>
                  Show Group Challenge:
                  <input
                    type="checkbox"
                    checked={round.showGroupChallenge}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'showGroupChallenge', e.target.checked)
                    }
                  />
                </label>
                <label>
                  Show Group Leaderboard:
                  <input
                    type="checkbox"
                    checked={round.showGroupLeaderboard}
                    onChange={(e) =>
                      handleRoundChange(trackIndex, roundIndex, 'showGroupLeaderboard', e.target.checked)
                    }
                  />
                </label>
                <button
                  type="button"
                  onClick={() => deleteRound(trackIndex, roundIndex)}
                  className="delete-button"
                >
                  Delete Round
                </button>
              </div>
            ),
          })),
        ]}
        onAddTab={() => addRound(trackIndex)}
        addTabLabel="+ Round"
      />
    </div>
  ),
}));

  return (
    <form className="groupWorkoutForm" onSubmit={handleSubmit}>
      <HorizontalTabs
        tabs={[overviewTab, ...tracksTabs]}
        onAddTab={addTrack}
        addTabLabel="+ Track" // Label for adding tracks
      />
      {finalAudioDownloadLocation && (
        <div className="groupWorkoutForm__audio">
          <AudioDownloaderPlayer
            ref={audioPlayerRef}
            downloadLocation={finalAudioDownloadLocation}
            token={localStorage.getItem('NEXTROUND_API') || ''}
          />
        </div>
      )}
      <div className="groupWorkoutForm__actions">
        <button type="submit" disabled={updateLoading}>
          {updateLoading ? 'Updating...' : 'Update Group Workout'}
        </button>

        <button
          type="button"
          onClick={() => sortEverything()}
          className="button"
        >
          Sort tracks and rounds
        </button>
        {updateError && <p className="error">Error: {updateError.message}</p>}
      </div>
    </form>
  );
}

export default EditGroupWorkoutForm;
