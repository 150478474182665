const SkipIcon = ({ isLeft = true }) => {
  return (
      <svg
        width="33"
        height="26"
        viewBox="0 0 33 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform={isLeft ? '' : 'rotate(180)'}
      >
        <path
          d="M29.75 24.5625C31.0625 25.625 33 24.75 33 23V3.0625C33 1.3125 31.0625 0.4375 29.75 1.5L19 10.4375V15.625L29.75 24.5625ZM13.75 24.5625C15.0625 25.625 17 24.75 17.0625 23.125V3.125C17.0625 1.4375 15.0625 0.5625 13.75 1.625L1.75 11.5625C0.8125 12.375 0.8125 13.8125 1.75 14.625L13.75 24.5625Z"
          fill="white"
        />
      </svg>
  )
}

export default SkipIcon
