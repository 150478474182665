import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

interface AudioDownloaderPlayerProps {
  downloadLocation: string;
  token: string | null;
}

export interface AudioDownloaderPlayerHandle {
  setPlaybackTime: (time: number) => void;
}

const AudioDownloaderPlayer = forwardRef<AudioDownloaderPlayerHandle, AudioDownloaderPlayerProps>(
  ({ downloadLocation, token }, ref) => {
    const [audioUrl, setAudioUrl] = useState<string | null>(null);
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
      async function downloadAudio() {
        try {
          const response = await fetch(downloadLocation, {
            headers: {
              Authorization: token || '',
              'X-Authorization-Method': 'api',
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to download file: ${response.status}`);
          }

          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setAudioUrl(url);

          return () => {
            if (url) {
              URL.revokeObjectURL(url);
            }
          };
        } catch (err) {
          console.error('Error downloading audio:', err);
        }
      }

      downloadAudio();
    }, [downloadLocation, token]);

    useImperativeHandle(ref, () => ({
      setPlaybackTime: (time: number) => {
        if (audioRef.current) {
          audioRef.current.currentTime = time;
          audioRef.current.play(); // Optional: Start playback immediately
        }
      },
    }));

    return (
      <div>
        {audioUrl ? (
          <audio controls src={audioUrl} ref={audioRef} />
        ) : (
          <p>Loading audio...</p>
        )}
      </div>
    );
  }
);

export default AudioDownloaderPlayer;
