import React, { useState } from 'react';
import './horizontal-tabs.scss'; // Ensure styles are defined here.

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface HorizontalTabsProps {
  tabs: Tab[];
  onAddTab: () => void;
  addTabLabel?: string; // New optional prop for the add button label
}

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({ tabs, onAddTab, addTabLabel = '+ Add Tab' }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <div className="horizontal-tabs">
      <div className="horizontal-tabs__header">
        <div className="horizontal-tabs__tabs-container">
          {tabs.map((tab, index) => (
            <button
              key={index}
              type="button"
              className={`horizontal-tabs__tab ${activeTabIndex === index ? 'active' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </button>
          ))}
          <button type="button" className="horizontal-tabs__add-tab" onClick={onAddTab}>
            {addTabLabel}
          </button>
        </div>
      </div>
      <div className="horizontal-tabs__content">
        {tabs[activeTabIndex] && tabs[activeTabIndex].content}
      </div>
    </div>
  );
};

export default HorizontalTabs;
