import './style.scss'
import boxbag from './boxbag.svg'
import backArrow from './back-arrow.svg'

interface ButtonRowProps {
  start: string
  handleStartTraining: () => void
  close: string
  closePopup: () => void
  isSetup?: boolean
}

const ButtonRow: React.FC<ButtonRowProps> = ({ start, handleStartTraining, close, closePopup, isSetup = true }) => {
  return (
    <div className="flex-style">
      <button onClick={closePopup} className="button-style-group border-style group-workout-font-family">
        <div className="flex-style item-style">
          <img
            src={backArrow}
            alt="backArrow"
            style={{ height: '2rem', transform: 'rotate(180deg)', alignItems: 'center' }}
          />
          {close.toUpperCase()}
        </div>
      </button>

      <button
        onClick={() => handleStartTraining()}
        className="button-style-group button-gradient group-workout-font-family"
      >
        {start.toUpperCase()}
        {isSetup ? (
          <img src={boxbag} alt="boxbag" className="boxbag" />
        ) : (
          <img src={backArrow} alt="backArrow" style={{ height: '2rem' }} />
        )}
      </button>
    </div>
  )
}

export default ButtonRow
