import React, { useState, useEffect } from 'react';

interface ImageDownloaderViewerProps {
  downloadLocation: string;
  token: string | null;
}

function ImageDownloaderViewer({ downloadLocation, token }: ImageDownloaderViewerProps) {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);

  useEffect(() => {
    async function downloadImage() {
      try {
        const response = await fetch(downloadLocation, {
          headers: {
            'Authorization': token || '',
            'X-Authorization-Method': 'api'
          }
        });

        if (!response.ok) {
          throw new Error(`Failed to download file: ${response.status}`);
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setThumbnailUrl(url);

        // Cleanup: revoke the URL when the component unmounts or when url changes
        return () => {
          if (url) {
            URL.revokeObjectURL(url);
          }
        };
      } catch (err) {
        console.error('Error downloading audio:', err);
      }
    }

    downloadImage();
  }, [downloadLocation, token]);

  return (
    <div>
      {thumbnailUrl ? (
        <img src={thumbnailUrl} alt="Thumbnail group workout" />
      ) : (
        <p>Loading image...</p>
      )}
    </div>
  );
}

export default ImageDownloaderViewer;
