import React from "react";
import "./loading-spinner.scss";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"/>
      <div style={{position: "absolute"}}>Loading...</div>
    </div>
  );
};

export default LoadingSpinner;
