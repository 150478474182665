import React from 'react'
import { INRWorkoutSessionHit } from 'interfaces/workout'
import { GroupWorkoutHit, GroupBag } from 'interfaces/group-workout'
import {
  BarChart,
  Bar,
  LabelList,
  Cell,
  ResponsiveContainer,
  YAxis,
  CartesianGrid,
} from 'recharts'
import './group-hit-challenge.scss'

// Props for GroupIntensity component
interface GroupHitChallengeProps {
  groupBags: GroupBag[]
  groupWorkoutHits: GroupWorkoutHit[]
}

const colors: string[] = [
  '#FF7733',
  '#F7D126',
  '#F726D1',
  '#F0F726',
  '#F04D26',
  '#7E68AE',
  '#7857FF',
  '#75C7FF',
  '#70ED99',
  '#00AFB9',
]

// GroupIntensity component
const GroupHitChallenge: React.FC<GroupHitChallengeProps> = ({ groupBags, groupWorkoutHits }) => {
  const hitsByBag = groupWorkoutHits.reduce((acc, hit) => {
    const bagId = hit.bag.id
    if (!acc[bagId]) {
      acc[bagId] = []
    }
    const sessionHit: INRWorkoutSessionHit = {
      angle: 0, // Default value
      force: hit.force,
      height: 0, // Default value
      punchedAt: hit.punchedAt,
    }

    acc[bagId].push(sessionHit)
    return acc
  }, {} as Record<string, INRWorkoutSessionHit[]>)
  // Calculate intensity for each bag
  const bagIntensities = groupBags.map((groupBag) => {
    const bagId = groupBag.bag.id
    const hitsForBag = hitsByBag[bagId] || []
    return {
      bagId,
      hitsCount: hitsForBag.length,
    }
  })

  const maxHitsCount = Math.max(...bagIntensities.map((bag) => bag.hitsCount))
  const sortedData = [...bagIntensities].sort((a, b) => b.hitsCount - a.hitsCount)
  const top3BagIds = sortedData.slice(0, 3).map((item) => item.bagId)

  const renderCustomizedLabel = (props: { x: number; y: number; index: number; width: number }) => {
    const { x, y, index, width } = props
    const radius = 10
    const bagId = bagIntensities[index].bagId
    const rank = top3BagIds.indexOf(bagId) + 1
    const colors = ['#d5a500', '#b7b7b7', '#A17419']
    const fill = colors[rank - 1]

    return sortedData[0].hitsCount > 0 && rank > 0 && rank <= 3 ? (
      <g>
        <circle cx={x + width / 2} cy={y - radius - 15} r={radius + 10} fill={fill} />
        <text x={x + width / 2} y={y - radius - 14} fill="#FFF" textAnchor="middle" dominantBaseline="middle">
          {rank}
        </text>
      </g>
    ) : null
  }

  return (
    <div>
      <h2>Hits challenge</h2>
      <ResponsiveContainer className="barchart-custom" width="60%" height={600} style={{ margin: '0 auto' }}>
        <BarChart data={bagIntensities} margin={{ top: 50 }}>
          <YAxis
            hide={true}
            domain={[0, (dataMax: number) => Math.max(dataMax, 10) * 1.1]}
            tickCount={6}
            tickFormatter={(value) => Math.floor(value).toString()}
            allowDecimals={false}
          />

          <CartesianGrid
            vertical={true}
            horizontal={false}
            stroke="#000"
            strokeWidth={2}
          />

          <defs>
            {bagIntensities.map(({ bagId }, index) => (
              <linearGradient key={`gradient-${bagId}`} id={`gradient-${bagId}`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="25%" stopColor={colors[index]} />
                <stop offset="100%" stopColor="#fff" />
              </linearGradient>
            ))}
          </defs>

          <Bar dataKey="hitsCount" isAnimationActive={false} barSize={200} radius={[10, 10, 0, 0]} maxBarSize={20}>
            {bagIntensities.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`url(#gradient-${entry.bagId})`} />
            ))}
            <LabelList
              dataKey="hitsCount"
              position="insideBottom"
              dy={-10}
              fill="#000"
              style={{ fontWeight: 'bold' }}
            />
            <LabelList dataKey="hitsCount" position="top" fill="#000" content={renderCustomizedLabel as any} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default GroupHitChallenge
