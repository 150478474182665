import { DifficultyLevel } from 'interfaces/workout'

export const pathsObj = {
  main: {
    home: '/',
    api: '/api',
  },
  workouts: {
    workouts: '/workouts',
    workoutComplete: '/workout-complete',
    workoutOverview: {
      default: '/workout-overview/:id/:level',
      getWorkoutOverview: (id: string, level: DifficultyLevel | string) => `/workout-overview/${id}/${level}`,
    },
    workoutNew: '/workout-new',
  },
  rounds: {
    roundOverview: '/round-overview',
    roundActive: '/round-active',
    roundComplete: '/round-complete',
    exerciseCountdown: '/exercise-countdown',
  },
  freestyle: {
    freestyleActive: '/freestyle-active',
  },
  groupWorkout: {
    createGroupWorkout: '/group-workout/create',
    editGroupWorkoutOverview: '/group-workout/overview',
    editGroupWorkout: '/group-workout/:id/edit',
    home: '/new-home',
    workouts: '/group-workouts',
    workout: '/group-workout/workout/:id',
    setupNewWorkout: '/group-workout/setup-new-workout/:id',
    preWorkout: '/group-workout/pre-workout/:id',
  },
}
