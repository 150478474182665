import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import Controls from './controls/controls'
import Main from 'layout/main'
import useControls from '../../hooks/controls'
import { useLocation, useNavigate } from 'react-router-dom'
import GroupWorkoutHeader from 'pages/group-workout/components/header'
import TrackList from './header/trackList'
import { useGroupWorkoutByIdQuery } from 'api/queries/group-workouts'
import { useCurrentGroupWorkout, useGroupWorkoutLoading, useGroupWorkoutError } from 'store/group-workout/selectors'
import { useGroupWorkoutSessionByIdQuery } from 'api/queries/group-workout-session'
import HitSimulate from 'components/hit-simulate/hit-simulate'
import useAddGroupHit from 'lib/hooks/useAddGroupHit'
import {
  useCurrentGroupWorkoutSession,
  useGroupWorkoutSessionActiveTrack,
  useGroupWorkoutSessionActiveRound,
  useGroupWorkoutSessionActions,
} from 'store/group-workout-session/selectors'
import GroupIntensity from 'components/group-workout/group-intensity'
import GroupHitChallenge from 'components/group-workout/group-hit-challenge'
import GroupGroupChallenge from 'components/group-workout/group-group-challenge'
import CircularTimer from 'components/group-workout/circular-timer'
import { SERVER_TIME } from 'api/queries/server-time'
import initApollo from 'api/client'
import { useSaveGroupWorkoutSession } from 'api/mutations/group-workout-session'
import GroupPause from 'components/popup/group-pause/group-pause'

const GroupWorkout: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const activeTrack: number = useGroupWorkoutSessionActiveTrack()
  const activeRound: number = useGroupWorkoutSessionActiveRound()
  const { updateActiveRound, updateActiveTrack } = useGroupWorkoutSessionActions()
  const { id } = location.state as { id: string }
  const groupWorkoutSession = useCurrentGroupWorkoutSession()

  useEffect(() => {
    console.log('groupWorkoutSession updated:', groupWorkoutSession)
  }, [groupWorkoutSession])
  const { saveGroupWorkoutSession } = useSaveGroupWorkoutSession()
  //console.log(groupWorkoutSession);
  useGroupWorkoutSessionByIdQuery(id, {
    skip: !!groupWorkoutSession && groupWorkoutSession.id === id,
  })
  const groupWorkout = useCurrentGroupWorkout()
  useGroupWorkoutByIdQuery(groupWorkoutSession?.groupWorkout?.id ?? '', {
    skip: !!groupWorkout && !!groupWorkoutSession && groupWorkout.id === groupWorkoutSession?.groupWorkout?.id,
  })
  // Access loading and error states from Zustand store via selectors
  const loading = useGroupWorkoutLoading()
  const error = useGroupWorkoutError()

  // Access groupWorkouts data from Zustand store via selectors
  const [rounds, setRounds] = useState<any[]>([])
  useAddGroupHit()

  const [showPopup, toggleShowPopup] = useState<boolean>(false)

  const sessionTracks = groupWorkoutSession?.groupWorkoutSessionTracks ?? []
  const workoutTracks = groupWorkout?.groupWorkoutTracks ?? []
  const sessionTrack = sessionTracks[activeTrack]
  const workoutTrack = workoutTracks[activeTrack]
  //console.log("activeTrack", activeTrack)
  //console.log("activeRound", activeRound)
  //console.log("workoutTracks", workoutTracks)
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const sessionRound = sessionTrack?.groupWorkoutSessionRounds?.[activeRound]
  const workoutRound = workoutTrack?.groupWorkoutTrackRounds?.[activeRound]
  //console.log(workoutRound)
  const [roundTime, setRoundTime] = useState<number>(10)
  // Handles switch logic.
  const [currentSwitchIndex, setCurrentSwitchIndex] = useState<number>(0)
  const [switchTimeLeft, setSwitchTimeLeft] = useState<number>(0)
  const switchInfo = workoutRound?.isSwitchRound
    ? {
        currentSwitch: currentSwitchIndex + 1,
        totalSwitches: workoutRound.totalSwitchRounds,
      }
    : null

  const client = initApollo()
  const [serverTime, setServerTime] = useState<number | null>(null)

  const getUpdatedServerTime = async () => {
    try {
      const { data } = await client.query({
        query: SERVER_TIME,
        fetchPolicy: 'no-cache',
      })
      return data.getServerTime.serverTime
    } catch (error) {
      console.error('Error fetching server time:', error)
      return null
    }
  }

  useEffect(() => {
    const fetchServerTime = async () => {
      const time = await getUpdatedServerTime()
      setServerTime(time)
    }

    fetchServerTime()
    const intervalId = setInterval(fetchServerTime, 200)

    // Clear the interval on component unmount
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const fetchServerTime = async () => {
      const time = await getUpdatedServerTime()
      setServerTime(time)
    }

    fetchServerTime()
    const intervalId = setInterval(fetchServerTime, 200)

    // Clear the interval on component unmount
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    if (workoutRound?.isSwitchRound && workoutRound?.timeInSwitchRound) {
      setSwitchTimeLeft(workoutRound.timeInSwitchRound) // Reset switch time
      setCurrentSwitchIndex(0) // Start from the first switch
    }
  }, [workoutRound])

  useEffect(() => {
    if (workoutRound?.duration) {
      setRoundTime(workoutRound.duration)
    }
  }, [workoutRound?.duration])

  useEffect(() => {
    if (groupWorkout) {
      const newRounds = groupWorkout.groupWorkoutTracks
        .filter((track) => track.groupWorkoutTrackRounds.length > 0 && !track.isPreWorkout)
        .flatMap((track) =>
          track.groupWorkoutTrackRounds.map((round) => ({
            id: round.id,
            name: round.name,
            duration: round.duration,
          }))
        )

      // Update only if the rounds actually changed
      setRounds((prevRounds) => {
        const roundsChanged = JSON.stringify(prevRounds) !== JSON.stringify(newRounds)
        return roundsChanged ? newRounds : prevRounds
      })
    }
  }, [loading, groupWorkout])

  const { isPaused, handlePause } = useControls()

  const customHandlePause = () => {
    toggleShowPopup(true)
    handlePause()
    if (isPaused) {
      audioRef.current?.play()
      videoRef.current?.play()
    } else {
      audioRef.current?.pause()
      videoRef.current?.pause()
    }
  }


  // Handle synchronization between timer and audio playback
  useEffect(() => {
    const handleRoundComplete = () => {
      if (!workoutTrack || !groupWorkoutSession) return

      const totalRounds = workoutTrack.groupWorkoutTrackRounds.length ?? 0
      if (totalRounds === 0) return

      const isLastRound = activeRound >= totalRounds - 1
      const isPreOrPostWorkout = workoutTrack.isPreWorkout || workoutTrack.isPostWorkout
      const updatedSessionData = {
        id: groupWorkoutSession.id, // Ensure the session ID is included
        groupWorkout: { id: groupWorkoutSession.groupWorkout.id },
        groupBags: groupWorkoutSession.groupBags.map((groupbag) => ({
          id: groupbag.id,
          isEnabled: groupbag.isEnabled,
          bag: {
            id: groupbag.bag.id,
            name: groupbag.bag.name,
            reference: groupbag.bag.reference,
            rank: groupbag.bag.rank,
          },
        })),
        groupWorkoutSessionTracks: groupWorkoutSession.groupWorkoutSessionTracks.map(
          (track: { id: any; trackId: any; sortOrder: any; groupWorkoutSessionRounds: any[] }) => ({
            id: track.id,
            trackId: track.trackId,
            sortOrder: track.sortOrder,
            groupWorkoutSessionRounds: track.groupWorkoutSessionRounds.map((round) => ({
              id: round.id,
              roundId: round.roundId,
              sortOrder: round.sortOrder,
              groupWorkoutHits: round.groupWorkoutHits.map(
                (hit: { id: any; force: any; punchedAt: any; bag: { id: any } }) => ({
                  id: hit.id,
                  force: hit.force,
                  punchedAt: hit.punchedAt,
                  bag: { id: hit.bag.id },
                })
              ),
            })),
          })
        ),
      }

      console.log('groupWorkoutSession input', updatedSessionData)
      // Save the session before transitioning to the next round
      saveGroupWorkoutSession(updatedSessionData)
      if (isPreOrPostWorkout) {
        // Loop within the same track
        console.log('Looping within pre/post workout')
        updateActiveRound(isLastRound ? 0 : activeRound + 1)
      } else {
        const isLastTrack = activeTrack >= workoutTracks.length - 1
        if (isLastRound) {
          if (!isLastTrack) {
            console.log('Moving to the next track')
            updateActiveTrack(activeTrack + 1) // Move to the next track
            updateActiveRound(0) // Reset to the first round
          } else {
            console.log('Moving to the next round')
            updateActiveRound(activeRound + 1) // Move to the next round
          }
          const audioElement = audioRef.current

          if (!audioElement || !workoutRound) return

          const syncTimerWithAudio = () => {
            const roundStartTime = workoutRound.calculatedAudioTime
            const timeLeft = Math.max(workoutRound.duration - (audioElement.currentTime - roundStartTime), 0)
            setRoundTime(timeLeft)
            if (timeLeft === 0) {
              handleRoundComplete()
            }
          }

          audioElement.addEventListener('timeupdate', syncTimerWithAudio)

          return () => {
            audioElement.removeEventListener('timeupdate', syncTimerWithAudio)
          }
        }
      }
    }
  }, [
    activeRound,
    activeTrack,
    groupWorkoutSession,
    saveGroupWorkoutSession,
    updateActiveRound,
    updateActiveTrack,
    workoutRound,
    workoutTrack,
    workoutTracks.length,
  ])

  useEffect(() => {
    const handleSwitchComplete = () => {
      if (!workoutRound) return

      const isLastSwitch = currentSwitchIndex >= workoutRound.totalSwitchRounds - 1
      if (isLastSwitch) {
        console.log('All switches for this round are complete.')
        setSwitchTimeLeft(0) // Ensure the timer stays at 0
      } else {
        console.log('Moving to the next switch.')
        setCurrentSwitchIndex(currentSwitchIndex + 1) // Increment the switch index
        setSwitchTimeLeft(workoutRound.timeInSwitchRound) // Reset the timer for the next switch
      }
    }

    const audioElement = audioRef.current

    if (!audioElement || !workoutRound?.isSwitchRound) return

    const syncSwitchTimerWithAudio = () => {
      const switchStartTime = workoutRound.calculatedAudioTime + currentSwitchIndex * workoutRound.timeInSwitchRound // Calculate start time for the current switch
      const timeLeft = Math.max(workoutRound.timeInSwitchRound - (audioElement.currentTime - switchStartTime), 0)
      setSwitchTimeLeft(timeLeft)

      if (timeLeft === 0) {
        handleSwitchComplete() // Handle completion of the current switch
      }
    }

    audioElement.addEventListener('timeupdate', syncSwitchTimerWithAudio)

    return () => {
      audioElement.removeEventListener('timeupdate', syncSwitchTimerWithAudio)
    }
  }, [workoutRound, currentSwitchIndex])

  // Reset switch index and timer when the round changes
  useEffect(() => {
    if (workoutRound?.isSwitchRound) {
      setCurrentSwitchIndex(0) // Start from the first switch
      setSwitchTimeLeft(workoutRound.timeInSwitchRound) // Set the timer for the first switch
    }
  }, [workoutRound])

  // Handle next and previous round transitions
  const customHandleNext = () => {
    if (!workoutTrack) return

    const isLastRound = activeRound >= workoutTrack.groupWorkoutTrackRounds.length - 1
    if (isLastRound) {
      const isLastTrack = activeTrack >= workoutTracks.length - 1
      if (!isLastTrack) {
        const nextTrack = workoutTracks[activeTrack + 1]
        const nextRound = nextTrack.groupWorkoutTrackRounds[0]
        if (audioRef.current) {
          audioRef.current.currentTime = nextRound.calculatedAudioTime
        }
        updateActiveTrack(activeTrack + 1)
        updateActiveRound(0)
      }
    } else {
      const nextRound = workoutTrack.groupWorkoutTrackRounds[activeRound + 1]
      if (audioRef.current) {
        audioRef.current.currentTime = nextRound.calculatedAudioTime
      }
      updateActiveRound(activeRound + 1)
    }
  }

  const customHandlePrev = () => {
    if (activeRound > 0) {
      const prevRound = workoutTrack.groupWorkoutTrackRounds[activeRound - 1]
      if (audioRef.current) {
        audioRef.current.currentTime = prevRound.calculatedAudioTime
      }
      updateActiveRound(activeRound - 1)
    } else if (activeTrack > 0) {
      const previousTrack = workoutTracks[activeTrack - 1]
      const lastRoundIndex = previousTrack.groupWorkoutTrackRounds.length - 1
      const prevRound = previousTrack.groupWorkoutTrackRounds[lastRoundIndex]
      if (audioRef.current) {
        audioRef.current.currentTime = prevRound.calculatedAudioTime
      }
      updateActiveTrack(activeTrack - 1)
      updateActiveRound(lastRoundIndex)
    }
  }

  useEffect(() => {
    // Update the workoutRound and reset the timer whenever the active round or track changes
    if (workoutTrack && activeRound < workoutTrack.groupWorkoutTrackRounds?.length) {
      const newWorkoutRound = workoutTrack.groupWorkoutTrackRounds[activeRound]
      if (newWorkoutRound?.duration) {
        console.log('Resetting round time to', newWorkoutRound.duration)
        setRoundTime(newWorkoutRound.duration) // Reset the round timer
      }
    }
  }, [activeTrack, activeRound, workoutTrack])

  // Play Audio on Component Load
  useEffect(() => {
    if (audioRef.current && groupWorkout?.audioFile?.blobUrl) {
      audioRef.current.play().catch((err) => {
        console.error('Failed to autoplay audio:', err)
      })
    }
  }, [groupWorkout])

  const closePopup = () => {
    toggleShowPopup(false)
    navigate('/group-workouts')
  }

  const resumeWorkout = () => {
    toggleShowPopup(false)
    handlePause()
    if (isPaused) {
      audioRef.current?.play()
      videoRef.current?.play()
    } else {
      audioRef.current?.pause()
      videoRef.current?.pause()
    }
  }

  return (
    <Main>
      <div className="workout-new">
        {showPopup && <GroupPause resumeWorkout={() => resumeWorkout()} closePopup={() => closePopup()} />}

        <GroupWorkoutHeader>
          <TrackList rounds={rounds || []} currentRound={rounds[0]} />
        </GroupWorkoutHeader>
        {/* <Header
            title={getCurrentTrackTitle()}
            time={getCurrentTrackTime()}
            totalTime={totalTime}
            tracks={training.tracks}
            activeTrackId={trackIndex}
          />
         <Timer duration={parseInt(getCurrentTrackTime())} /> */}
        {/* Video for the current round */}
        {groupWorkout?.audioFile?.blobUrl ? (
          <audio ref={audioRef} src={groupWorkout.audioFile.blobUrl} autoPlay loop controls className="hidden-audio">
            Your browser does not support the audio element.
          </audio>
        ) : (
          <p>Loading audio...</p>
        )}
        {workoutRound?.videoFile?.blobUrl ? (
          <div className="video-wrapper">
            <video
              ref={videoRef}
              id={`video-${workoutRound.id}`}
              src={workoutRound.videoFile.blobUrl}
              autoPlay
              loop
              muted
              playsInline
              className="fullscreen-video"
            />
          </div>
        ) : (
          <p>Loading video...</p>
        )}
        {workoutRound?.showIntensity && sessionRound && (
          <div className="intensity-wrapper">
            <GroupIntensity
              serverTime={serverTime}
              groupBags={groupWorkoutSession?.groupBags || []}
              groupWorkoutHits={sessionRound.groupWorkoutHits}
              exerciseSecond={workoutRound.timeInSwitchRound - switchTimeLeft}
            />
          </div>
        )}

        {workoutRound?.showHitScore && sessionRound && (
          <div className="group-hit-challenge-wrapper">
            <GroupHitChallenge
              groupBags={groupWorkoutSession?.groupBags || []}
              groupWorkoutHits={sessionRound.groupWorkoutHits}
            />
          </div>
        )}

        {workoutRound?.showGroupChallenge && sessionRound && (
          <div className="group-group-challenge-wrapper">
            <GroupGroupChallenge
              groupBags={groupWorkoutSession?.groupBags || []}
              groupWorkoutHits={sessionRound.groupWorkoutHits}
            />
          </div>
        )}

        {workoutTrack ? (
          <div className="round-info">
            <h2>
              {workoutTrack.name}
              {workoutTrack.isPreWorkout && ' (Pre-Workout)'}
              {workoutTrack.isPostWorkout && ' (Post-Workout)'}
            </h2>
            <h3>Round: {workoutRound?.name}</h3>
            <p>Time Remaining: {roundTime}</p>
            <CircularTimer
              roundDuration={workoutRound.duration}
              timeLeftInRound={roundTime}
              isPaused={isPaused}
              showSwitch={false}
            />
            {workoutRound?.isSwitchRound && switchInfo && (
              <div className="switch-timer">
                <h3>
                  Switch {currentSwitchIndex + 1} of {workoutRound.totalSwitchRounds}
                </h3>
                <p>Time Remaining in Switch: {switchTimeLeft}</p>
                <CircularTimer
                  roundDuration={workoutRound.duration}
                  timeLeftInRound={roundTime}
                  timeLeftInSwitch={switchTimeLeft}
                  switchDuration={workoutRound.timeInSwitchRound}
                  isPaused={isPaused}
                  showSwitch={true}
                  switchInfo={{
                    currentSwitchRound: switchInfo.currentSwitch,
                    totalSwitches: switchInfo.totalSwitches,
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <p>Loading or no track data...</p>
        )}

        <HitSimulate />
        <Controls handlePause={customHandlePause} handlePrev={customHandlePrev} handleNext={customHandleNext} />
      </div>
    </Main>
  )
}

export default GroupWorkout
