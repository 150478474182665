import { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'

import handleStorage, { TOKENNAME } from 'lib/handleStorage'
import { useTabletsBagsQuery } from 'api/queries/tablets-bags'

const PUNCH = gql`
  mutation punch($bagId: String!) {
    punch(bagId: $bagId) {
      angle
    }
  }
`

const { stored: apiKey } = handleStorage(TOKENNAME.api)

const filterDataByKey = (data: any) => {
  return data.apiKey.find((item: any) => item.key === JSON.parse(apiKey))
}

const useSimulateHits = () => {
  const { data } = useTabletsBagsQuery()
  const [simulateHits] = useMutation(PUNCH)

  const executeHit = () => {
    const filtered = filterDataByKey(data)
    const bags = filtered?.tablet?.[0]?.bag || []
    if (bags.length) {
      const randomIndex = Math.floor(Math.random() * bags.length)
      const randomBag = bags[randomIndex]
      return simulateHits({ variables: { bagId :  randomBag.id } })
    }
  }

  return executeHit
}

export default useSimulateHits
