// src/stores/groupWorkout/index.tsx
import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { GroupWorkoutSession, GroupWorkoutHit } from 'interfaces/group-workout';

export interface GroupWorkoutSessionStore {
  groupWorkoutSessions: GroupWorkoutSession[];
  currentGroupWorkoutSession: GroupWorkoutSession | null;
  loading: boolean;
  error: string | null;
  activeRound: number;
  activeTrack: number;
  actions: {
    setGroupWorkoutSessions: (workoutSessions: GroupWorkoutSession[]) => void;
    setGroupWorkoutSessionById: (workoutSession: GroupWorkoutSession) => void;
    updateGroupWorkoutSession: (workoutSession: GroupWorkoutSession) => void;
    deleteGroupWorkoutSession: (id: string) => void;
    clearCurrentGroupWorkoutSession: () => void;
    setLoading: (loading: boolean) => void;
    setError: (error: string | null) => void;
    updateActiveRound: (roundNum: number) => void;
    updateActiveTrack: (trackNum: number) => void;
    addGroupWorkoutHit: (hit: GroupWorkoutHit) => void;
    toggleGroupBagEnabled: (groupBagId: string) => void;
  };
}

const useGroupWorkoutSessionStore = create<GroupWorkoutSessionStore>((set, get) => ({
  groupWorkoutSessions: [],
  currentGroupWorkoutSession: null,
  loading: false,
  error: null,
  activeRound: 0,
  activeTrack: 0,
  actions: {
    setGroupWorkoutSessions: (workoutSessions: GroupWorkoutSession[]) => set({ groupWorkoutSessions: workoutSessions }),
    setGroupWorkoutSessionById: (workoutSession: GroupWorkoutSession) => set({ currentGroupWorkoutSession: workoutSession }),
    updateGroupWorkoutSession: (workoutSession: GroupWorkoutSession) =>
      set((state) => ({
        groupWorkoutSessions: state.groupWorkoutSessions.map((gw) =>
          gw.id === workoutSession.id ? workoutSession : gw
        ),
        currentGroupWorkoutSession:
          state.currentGroupWorkoutSession?.id === workoutSession.id ? workoutSession : state.currentGroupWorkoutSession,
      })),
    deleteGroupWorkoutSession: (id: string) =>
      set((state) => ({
        groupWorkoutSessions: state.groupWorkoutSessions.filter((gw) => gw.id !== id),
        currentGroupWorkoutSession:
          state.currentGroupWorkoutSession?.id === id ? null : state.currentGroupWorkoutSession,
      })),
      clearCurrentGroupWorkoutSession: () => set({ currentGroupWorkoutSession: null }),
    setLoading: (loading: boolean) => set({ loading }),
    setError: (error: string | null) => set({ error }),
    updateActiveRound: (roundNum) => set(() => ({ activeRound: roundNum })),
    updateActiveTrack: (trackNum) => set(() => ({ activeTrack: trackNum })),
    addGroupWorkoutHit: (hit) =>
      set((state) => {
        const { currentGroupWorkoutSession, activeRound, activeTrack } = state;

        if (!currentGroupWorkoutSession) {
          console.log("No current workout session");
          return {};
        }

        const updatedSession = { ...currentGroupWorkoutSession }; // New object reference
        const track = updatedSession.groupWorkoutSessionTracks?.[activeTrack];
        if (!track) return {};

        const round = track.groupWorkoutSessionRounds?.[activeRound];
        if (!round) return {};

        // Create new arrays to ensure React detects changes
        const updatedHits = [...round.groupWorkoutHits, hit];
        const updatedRound = { ...round, groupWorkoutHits: updatedHits };
        const updatedRounds = track.groupWorkoutSessionRounds.map((r, idx) =>
          idx === activeRound ? updatedRound : r
        );
        const updatedTrack = { ...track, groupWorkoutSessionRounds: updatedRounds };
        const updatedTracks = updatedSession.groupWorkoutSessionTracks.map((t, idx) =>
          idx === activeTrack ? updatedTrack : t
        );

        updatedSession.groupWorkoutSessionTracks = updatedTracks;

        return { currentGroupWorkoutSession: updatedSession };
      }),
    toggleGroupBagEnabled: (groupBagId: string) =>
      set((state) => {
        const { currentGroupWorkoutSession } = state;
        if (!currentGroupWorkoutSession?.groupBags) return {};
  
        // create updated groupBags
        const updatedBags = currentGroupWorkoutSession.groupBags.map((groupBag) =>
          groupBag.id === groupBagId ? { ...groupBag, isEnabled: !groupBag.isEnabled } : groupBag
        );
  
        const updatedSession = {
          ...currentGroupWorkoutSession,
          groupBags: updatedBags,
        };
        console.log(updatedSession)
        return {
          currentGroupWorkoutSession: updatedSession,
          // also update any matching session in groupWorkoutSessions
          groupWorkoutSessions: state.groupWorkoutSessions.map((gw) =>
            gw.id === updatedSession.id ? updatedSession : gw
          ),
        };
      }),
  },
  
}));

// Enable Zustand devtools in development
if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('GroupWorkoutSessionStore', useGroupWorkoutSessionStore);
}

export default useGroupWorkoutSessionStore;
export type { GroupWorkoutSession };
