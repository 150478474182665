import React, { useState, useEffect, useRef } from 'react'

import Button from 'components/button'
import useSimulateHits from 'lib/hooks/useSimulateHits'
import './hit-simulate.scss'

// This button is used for testing purposes only
// It simulates a punch on a boxing bag
// The mutation triggers a hit subscription with random values on the backend, which is then sent to the frontend (assuming the subscription is being listened to)
const HitSimulate = () => {
  const simulateHits = useSimulateHits()
  const [isSpawning, setIsSpawning] = useState(false)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const handleToggleSpawning = () => {
    if (isSpawning) {
      // if currently spawning, stop it
      setIsSpawning(false)
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    } else {
      // if not currently spawning, start it
      setIsSpawning(true)
      const intervalId = setInterval(() => {
        simulateHits()
      }, 300) // e.g. spawn hits every 300ms
      intervalRef.current = intervalId
    }
  }

  const handleSingleSpawn = () => {
    simulateHits()
  }

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const isProduction = process.env.REACT_APP_API_URL === 'api.nextroundboxing.com' // TODO handle this with explicit env vars

  return isProduction ? (
    <></>
  ) : (
    <div className="hitMeBtn">
      <Button customClickEvent={handleSingleSpawn}>
        <h3 className="customColor">Spawn hit!</h3>
      </Button>
      <Button customClickEvent={handleToggleSpawning}>
        <h3 className="customColor">  {isSpawning ? 'Stop Spawning' : 'Start Spawning'}</h3>
      </Button>
    </div>
  )
}

export default HitSimulate
