import React from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import SkipIcon from './skip-icon'
import backArrow from './back-arrow.svg'
import home from './home.svg'
import volume from './music-volume.svg'
import boxingbag from './boxingbag.svg'

interface ControlsProps {
  handlePause: () => void
  handlePrev: () => void
  handleNext: () => void
}

const Controls: React.FC<ControlsProps> = ({ handlePause, handlePrev, handleNext }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/new-home')
  }

  return (
    <div className="controls" style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
      <div style={{ display: 'flex', gap: '2rem' }}>
        <div
          onClick={handleClick}
          style={{ backgroundColor: 'black', cursor: 'pointer', height: '80px', width: '80px', alignContent: 'center' }}
        >
          <img src={home} alt="home" style={{ height: '2rem' }} />
        </div>
        <div
          onClick={() => window.history.back()}
          style={{ backgroundColor: 'black', cursor: 'pointer', height: '80px', width: '80px', alignContent: 'center' }}
        >
          <img src={backArrow} alt="backArrow" style={{ height: '2rem', transform: 'rotate(180deg)' }} />
        </div>
      </div>

      <div style={{ display: 'flex', gap: '2rem', justifyContent: 'center' }}>
        <button
          onClick={handlePrev}
          style={{
            backgroundColor: 'black',
            height: '80px',
            width: '80px',
            borderRadius: '50%',
            cursor: 'pointer',
            border: 'none',
          }}
        >
          <SkipIcon />
        </button>
        <button onClick={handlePause} className="button-style-group button-gradient group-workout-font-family">
          PAUSE
        </button>
        <button
          onClick={handleNext}
          style={{
            backgroundColor: 'black',
            height: '80px',
            width: '80px',
            borderRadius: '50%',
            cursor: 'pointer',
            border: 'none',
          }}
        >
          <SkipIcon isLeft={false} />
        </button>
      </div>

      <div style={{ display: 'flex', gap: '2rem' }}>
        <div
          style={{ backgroundColor: 'black', cursor: 'pointer', height: '80px', width: '80px', alignContent: 'center' }}
        >
          <img src={volume} alt="volume" style={{ height: '2rem' }} />
        </div>
        <div
          style={{ backgroundColor: 'black', cursor: 'pointer', height: '80px', width: '80px', alignContent: 'center' }}
        >
          <div style={{position: 'relative', right: '-54px', bottom: '20px', backgroundColor: 'white', color: 'black', borderRadius: '50%', width: '2rem', height: '2rem', alignContent: 'center', fontFamily: 'CanaroBold', }}>10</div>
          <img src={boxingbag} alt="boxing bag" style={{ height: '2rem' }} />
        </div>
      </div>
    </div>
  )
}

export default Controls
