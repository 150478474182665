// downloadFile.ts

/**
 * Downloads a file from the specified URL and returns a blob URL.
 *
 * @param downloadLocation - The URL from which to download the file.
 * @returns An object containing the blob URL and a revoke function, or null if download is skipped.
 * @throws Will throw an error if the download fails.
 */
export async function downloadFile(
  downloadLocation: string
): Promise<{ blobUrl: string; revoke: () => void } | null> {

  try {
    // Retrieve the token from localStorage
    let token = localStorage.getItem('NEXTROUND_API') || '';

    if (token && JSON.parse(token)) {
      token = JSON.parse(token)
      token = token?.toLowerCase().startsWith('basic') ? token : `Basic ${token}`
    }
    // Fetch the file from the specified location with appropriate headers
    const response = await fetch(downloadLocation, {
      headers: {
        Authorization: token,
        'X-Authorization-Method': 'api',
      },
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.status} ${response.statusText}`);
    }

    // Convert the response to a Blob
    const blob = await response.blob();

    // Create an object URL from the Blob
    const objectUrl = URL.createObjectURL(blob);

    // Return the blob URL and a revoke function for cleanup
    return {
      blobUrl: objectUrl,
      revoke: () => {
        URL.revokeObjectURL(objectUrl);
        console.log('Blob URL revoked:', objectUrl);
      },
    };
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error; // Propagate the error to the caller
  }
}

// Generic type for objects that have a download location
type Downloadable<T extends string> = Partial<Record<T, string>> & {
  [key: string]: any;
};

type MediaType = 'image' | 'video' | 'audio';

/**
 * Downloads a file and assigns the blob URL to the target key after validating.
 *
 * @template T - The type of the object containing the download key.
 * @template K - The key in the object that holds the download URL.
 * @template V - The key in the object where the blob URL should be assigned.
 *
 * @param obj - The object containing the download location.
 * @param downloadKey - The key in the object where the download URL is stored.
 * @param targetKey - The key in the object where the blob URL should be assigned.
 * @param mediaType - The type of media ('image', 'video', 'audio').
 * @returns A new object with the assigned blob URL or the original if validation fails.
 */
export async function downloadAndAssign<
  T extends Downloadable<K>,
  K extends string,
  V extends string
>(
  obj: T,
  downloadKey: K,
  targetKey: V,
  mediaType: MediaType
): Promise<T & Record<V, string | null>> {
  const downloadLocation = obj[downloadKey];
  const existingBlobUrl = obj[targetKey];

  if (typeof downloadLocation === 'string') {
    try {
      let isValid = false;

      if (existingBlobUrl) {
        switch (mediaType) {
          case 'image':
            isValid = await isImageBlobUrlValid(existingBlobUrl);
            break;
          case 'video':
            isValid = await isVideoBlobUrlValid(existingBlobUrl);
            break;
          case 'audio':
            isValid = await isAudioBlobUrlValid(existingBlobUrl);
            break;
          default:
            isValid = false;
        }
      }

      if (!isValid) {
        // Blob URL is invalid or does not exist, proceed to download
        const downloadResult = await downloadFile(downloadLocation);
        if (downloadResult && downloadResult.blobUrl) {
          return {
            ...obj,
            [targetKey]: downloadResult.blobUrl,
          };
        }
      } else {
        // Blob URL is still valid, no need to re-download
        return obj as T & Record<V, string | null>;
      }
    } catch (error) {
      console.error(`Error downloading file from ${downloadLocation}:`, error);
      // Optionally, handle the error (e.g., assign a default value or rethrow)
    }
  }

  // Return the original object if downloadLocation is not a string or download failed
  return obj as T & Record<V, string>;
}

/**
 * Checks if an audio blob URL is valid by attempting to load it.
 *
 * @param blobUrl - The audio blob URL to validate.
 * @returns A promise that resolves to `true` if valid, `false` otherwise.
 */
export async function isAudioBlobUrlValid(blobUrl: string): Promise<boolean> {
  return new Promise((resolve) => {
    const audio = document.createElement('audio');

    // Listen for canplay event to confirm it's loaded correctly
    audio.oncanplay = () => resolve(true);
    audio.onerror = () => resolve(false);

    audio.src = blobUrl;
    audio.load();
  });
}

/**
 * Checks if a video blob URL is valid by attempting to load it.
 *
 * @param blobUrl - The video blob URL to validate.
 * @returns A promise that resolves to `true` if valid, `false` otherwise.
 */
export async function isVideoBlobUrlValid(blobUrl: string): Promise<boolean> {
  return new Promise((resolve) => {
    const video = document.createElement('video');

    // Listen for metadata to confirm it's loaded correctly
    video.onloadedmetadata = () => resolve(true);
    video.onerror = () => resolve(false);

    video.src = blobUrl;
    video.load();
  });
}

/**
 * Checks if an image blob URL is valid by attempting to load it.
 *
 * @param blobUrl - The image blob URL to validate.
 * @returns A promise that resolves to `true` if valid, `false` otherwise.
 */
export async function isImageBlobUrlValid(blobUrl: string): Promise<boolean> {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);

    img.src = blobUrl;
  });
}
