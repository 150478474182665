 const DynamicLink = ({connected = true}) => {
  const connectedIcon = (
    <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg" className="link-hover">
      <path
        d="M0 6.5C0 3.0625 2.77344 0.25 6.25 0.25H8.75C9.41406 0.25 10 0.835938 10 1.5C10 2.20312 9.41406 2.75 8.75 2.75H6.25C4.17969 2.75 2.5 4.42969 2.5 6.5C2.5 8.57031 4.17969 10.25 6.25 10.25H8.75C9.41406 10.25 10 10.8359 10 11.5C10 12.2031 9.41406 12.75 8.75 12.75H6.25C2.77344 12.75 0 9.97656 0 6.5ZM22.5 6.5C22.5 9.97656 19.6875 12.75 16.25 12.75H13.75C13.0469 12.75 12.5 12.2031 12.5 11.5C12.5 10.8359 13.0469 10.25 13.75 10.25H16.25C18.3203 10.25 20 8.57031 20 6.5C20 4.42969 18.3203 2.75 16.25 2.75H13.75C13.0469 2.75 12.5 2.20312 12.5 1.5C12.5 0.835938 13.0469 0.25 13.75 0.25H16.25C19.6875 0.25 22.5 3.0625 22.5 6.5ZM7.5 5.25H15C15.6641 5.25 16.25 5.83594 16.25 6.5C16.25 7.20312 15.6641 7.75 15 7.75H7.5C6.79688 7.75 6.25 7.20312 6.25 6.5C6.25 5.83594 6.79688 5.25 7.5 5.25Z"
        fill="white"
      />
    </svg>
  )

  const disconnectedIcon = (
    <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="link-hover">
      <path
        d="M2.48438 0.734375L7.21094 4.40625C7.60156 4.32812 8.03125 4.25 8.5 4.25H11C11.6641 4.25 12.25 4.83594 12.25 5.5C12.25 6.20312 11.6641 6.75 11 6.75H10.2188L13.4219 9.25H17.25C17.9141 9.25 18.5 9.83594 18.5 10.5C18.5 11.2031 17.9141 11.75 17.25 11.75H16.5859L19.5938 14.0938C21.1172 13.625 22.25 12.2188 22.25 10.5C22.25 8.42969 20.5703 6.75 18.5 6.75H16C15.2969 6.75 14.75 6.20312 14.75 5.5C14.75 4.83594 15.2969 4.25 16 4.25H18.5C21.9375 4.25 24.75 7.0625 24.75 10.5C24.75 12.7656 23.5391 14.7188 21.7812 15.8125L25.6094 18.8594C26.0391 19.1719 26.1172 19.7578 25.7656 20.1484C25.4531 20.5781 24.8672 20.6562 24.4766 20.3047L1.35156 2.17969C0.921875 1.86719 0.84375 1.28125 1.19531 0.890625C1.50781 0.460938 2.09375 0.382812 2.48438 0.734375ZM5.72656 8C5.10156 8.66406 4.75 9.5625 4.75 10.5C4.75 12.5703 6.42969 14.25 8.5 14.25H11C11.6641 14.25 12.25 14.8359 12.25 15.5C12.25 16.2031 11.6641 16.75 11 16.75H8.5C5.02344 16.75 2.25 13.9766 2.25 10.5C2.25 8.97656 2.79688 7.53125 3.73438 6.4375L5.72656 8Z"
        fill="#E42313"
      />
    </svg>
  )

  return connected ? connectedIcon : disconnectedIcon
}

export default DynamicLink;
