import Main from 'layout/main'
import React from 'react'
import './style.scss'
import WorkoutGrid from 'pages/group-workout/components/workout-grid'
import GroupWorkoutHeader from 'pages/group-workout/components/header'
import GroupWorkoutFooter from 'pages/group-workout/components/footer'

const GroupWorkoutHome: React.FC = () => {
  return (
    <Main>
      <div className="group-workout-home">
      <GroupWorkoutHeader>
        <div className="group-workout-title">
          WELCOME TO NEXTROUND
        </div>
      </GroupWorkoutHeader>

        <WorkoutGrid />

        <GroupWorkoutFooter />
      </div>
    </Main>
  )
}

export default GroupWorkoutHome
