import ButtonRow from '../group-setup/button-row'
import './style.scss'

interface PopupProps {
  closePopup: () => void
  resumeWorkout: () => void
}

const GroupPause: React.FC<PopupProps> = ({ closePopup, resumeWorkout }) => {
  return (
    <div className="popupBackground" >
      <div className="popupWrapper setup-container">
        <div className="pause-container" style={{ padding: '2rem', height: '40vh' }}>
          <div style={{ position: 'relative', left: '50%', transform: 'translate(-50%, -50%)', top: '50%' }}>
            <div className="group-setup-title" style={{ fontSize: '4rem' }}>
              WORKOUT
            </div>
            <div className="group-setup-title" style={{ fontSize: '4rem' }}>
              PAUSED
            </div>
          </div>
        </div>
        <ButtonRow
          start="Keep going"
          handleStartTraining={resumeWorkout}
          close="Back off"
          closePopup={closePopup}
          isSetup={false}
        />
      </div>
    </div>
  )
}

export default GroupPause
