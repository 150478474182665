import React, { useEffect } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useNavigate } from 'react-router'

import { useWorkoutCategoriesQuery } from 'api/queries/workout-categories'
import { useWorkoutActions } from 'store/workout/selectors'
import { useSetPopup } from 'store/config/selectors'
import { pathsObj } from 'lib/constants/paths'
import { orderWorkoutCategories } from 'lib/workoutData'
import withIdleTimer from 'lib/HOC/withIdleTimer'
import Main from 'layout/main'
import { Spinner } from 'components/spinner/spinner'
import WorkoutCard from 'components/workouts/workout-card'
import Header from 'components/header/header'
import Button from 'components/button'
import { DifficultyLevel, INRWorkoutTemplate } from 'interfaces/workout'
import { useTabletsToggle } from 'api/queries/tablets-feature-toggle'

// Overview of all workouts available
const Workouts = () => {
  const navigate = useNavigate()
  const { resetWorkout } = useWorkoutActions()
  const { setFreestylePopup } = useSetPopup()

  const { data, loading } = useWorkoutCategoriesQuery()
  const { data: tabletData, loading: tabletLoading } = useTabletsToggle()

  const workoutClick = (workout: INRWorkoutTemplate) => {
    const level = workout?.variants[0]?.difficultyLevel ?? DifficultyLevel.EXPERIENCED
    const workoutId = workout?.id ?? ''
    navigate(pathsObj.workouts.workoutOverview.getWorkoutOverview(workoutId, level))
  }

  const handleGroupWorkoutClick = () => {
    navigate(pathsObj.groupWorkout.workouts) // Update to the actual path for group workout overview
  }

  useEffect(() => {
    resetWorkout()
  }, [resetWorkout])

  const canSeeGroupWorkouts = tabletData?.apiKey?.[0]?.tablet?.[0]?.canSeeClubJoyGroupWorkouts || false

  return (
    <Main className="nextround">
      <div>
        <Header title={<h2>Getting better with our workouts!</h2>} />
        <div className="workouts__config">
          <div className="workouts__config__imgWrapper">
            <div className="workouts__config__img" />
          </div>
          <div className="workouts__config__info">
            <h2>Freestyle</h2>
            <h3 className="light subtitle">Set up your workout</h3>
            <Button fullColor customClickEvent={setFreestylePopup}>
              <h3>Create now</h3>
            </Button>
          </div>
        </div>
        {/* Conditionally render group workout button */}
        {canSeeGroupWorkouts && (
          <div className="workouts__config__info">
            <Button fullColor customClickEvent={handleGroupWorkoutClick}>
              <h3>View Group Workouts</h3>
            </Button>
          </div>
        )}
        {loading && <Spinner className="loading" />}
        {!loading &&
          data &&
          orderWorkoutCategories(data.workoutTemplateCategory).map((category: any, key: number) => (
            <div className="workouts" key={key}>
              <h3>{category.name}</h3>
              <ScrollContainer className="workouts__scroll" key={key} vertical={false}>
                {category.templates.map((template: INRWorkoutTemplate, index: number) => {
                  const customClick = () => workoutClick(template);
                  return <WorkoutCard template={template} key={index} customClick={customClick} />;
                })}
              </ScrollContainer>
            </div>
          ))}
      </div>
    </Main>
  )
}

export default withIdleTimer(Workouts)
