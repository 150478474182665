import { useState } from 'react'
import { Training } from '../interfaces/training'

const useControls = () => {
  const [isPaused, setPause] = useState(false)
  const handlePause = () => {
    setPause(!isPaused)
  }

  const [isMuted, setMute] = useState(false)
  const handleMute = () => setMute(!isMuted)

  return { isPaused, isMuted, handlePause, handleMute }
}

export default useControls
