import React from 'react';
import Main from 'layout/main';
import Header from 'components/header/header';
import CreateGroupWorkoutForm from 'components/group-workout/create-group-workout-form'

const CreateGroupWorkoutPage = () => {
  return (
    <Main className="nextround">
      <div className="createGroupWorkoutPage">
        <Header title={<h1>Create Group Workout</h1>} />
        <CreateGroupWorkoutForm />
      </div>
    </Main>
  );
};

export default CreateGroupWorkoutPage;

