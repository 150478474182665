import React from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import GroupWorkoutsPage from './workouts'

const GroupWorkouts: React.FC = () => {
  const navigate = useNavigate()

  function handleWorkoutClick(id: string) {
    navigate(`/group-workout/setup-new-workout/${id}`, { state: { id } })
  }

  return <GroupWorkoutsPage navigateTo={handleWorkoutClick} />
}

export default GroupWorkouts
