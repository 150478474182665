import { useQuery, gql } from '@apollo/client'

const TABLET_TOGGLE = gql`
  query {
    apiKey {
      key
      tablet {
        reference
        canCreateGroupWorkouts
        canSeeClubJoyGroupWorkouts
      }
    }
  }
`

export const useTabletsToggle = () => useQuery(TABLET_TOGGLE)
