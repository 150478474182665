import React from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import GroupWorkoutPage from 'pages/group-workout/pages/workouts/workouts'

const EditGroupWorkoutOverview: React.FC = () => {
  const navigate = useNavigate()
  
  function handleWorkoutClick(id: string) {
    navigate(`/group-workout/${id}/edit`)
  }

  return <GroupWorkoutPage navigateTo={handleWorkoutClick} />
}

export default EditGroupWorkoutOverview
