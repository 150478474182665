// src/stores/groupWorkout/selectors.ts
import useGroupWorkoutSessionStore from './index';

// Selectors
export const useGroupWorkoutSessions = () =>
  useGroupWorkoutSessionStore((state) => state.groupWorkoutSessions);

export const useCurrentGroupWorkoutSession = () =>
  useGroupWorkoutSessionStore((state) => state.currentGroupWorkoutSession);

export const useGroupWorkoutSessionLoading = () =>
  useGroupWorkoutSessionStore((state) => state.loading);

export const useGroupWorkoutSessionError = () =>
  useGroupWorkoutSessionStore((state) => state.error);

export const useGroupWorkoutSessionActions = () =>
  useGroupWorkoutSessionStore((state) => state.actions);

export const useGroupWorkoutSessionById = (id: string) =>
  useGroupWorkoutSessionStore((state) =>
    state.groupWorkoutSessions.find((groupWorkoutSession) => groupWorkoutSession.id === id) || null
  );

export const useGroupWorkoutSessionActiveTrack = () =>
  useGroupWorkoutSessionStore((state) => state.activeTrack);

export const useGroupWorkoutSessionActiveRound = () =>
  useGroupWorkoutSessionStore((state) => state.activeRound);
