import React from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'

const GroupWorkoutFooter: React.FC = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/group-workout/workout')
  }

  return (
    <div style={{ width: '100vw', backgroundColor: 'transparent', justifyItems: 'center', minHeight: '8vh' }}>
      <div
        style={{
          alignSelf: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0.2rem',
          backgroundColor: 'red',
          padding: '1rem',
          minHeight: '8vh',
        }}
      >
        <div
          style={{
            height: '100%',
            color: 'white',
            backgroundColor: 'red',
            fontFamily: 'CanaroBold',
            fontSize: '1.5rem',
          }}
          onClick={handleClick}
        >
          QUICKSTART
        </div>
      </div>
    </div>
  )
}

export default GroupWorkoutFooter
