// src/pages/group-workout/pages/group-workouts.tsx
import React, { useState } from 'react';
import GroupWorkoutHeader from 'pages/group-workout/components/header';
import GroupWorkoutFooter from 'pages/group-workout/components/footer';
import Icon, { IconNames } from 'components/icons/icons';
import { useGroupWorkoutsQuery } from 'api/queries/group-workouts';
import { useGroupWorkouts, useGroupWorkoutLoading, useGroupWorkoutError } from 'store/group-workout/selectors';
import { useTabletsToggle } from 'api/queries/tablets-feature-toggle'
import { useNavigate } from 'react-router'
import { pathsObj } from 'lib/constants/paths'
import './style.scss'
import Main from 'layout/main'
import GroupSetup from 'components/popup/group-setup/group-setup'
import LoadingSpinner from 'components/group-workout/loading-spinner'
import { GroupWorkout } from 'interfaces/group-workout';

interface GroupWorkoutsProps {
  navigateTo: (id: string) => void
}

const GroupWorkoutsPage: React.FC<GroupWorkoutsProps> = ({ navigateTo }) => {
  const navigate = useNavigate()
  // Trigger the query by invoking the custom hook
  useGroupWorkoutsQuery()

  // Access loading and error states from Zustand store via selectors
  const loading = useGroupWorkoutLoading()
  const error = useGroupWorkoutError()

  // Access groupWorkouts data from Zustand store via selectors
  const groupWorkouts = useGroupWorkouts()

  const [showPopup, toggleShowPopup] = useState<boolean>(false)
  const [selectedWorkout, setSelectedWorkout] = useState<GroupWorkout | undefined>(undefined)

  const handleWorkoutClick = (groupWorkout: GroupWorkout) => {
    setSelectedWorkout(groupWorkout)
    toggleShowPopup(true)
  }

  const closePopup = () => {
    setSelectedWorkout(undefined)
    toggleShowPopup(false)
  }

  const { data: tabletData, loading: tabletLoading } = useTabletsToggle()

  const navigateToCreateWorkout = () => {
    navigate(pathsObj.groupWorkout.createGroupWorkout);
  };

  const canCreateGroupWorkouts =
    tabletData?.apiKey?.[0]?.tablet?.[0]?.canCreateGroupWorkouts || false
  return (
    <Main>
      <div className="group-workout-home">
        {showPopup && <GroupSetup groupWorkoutProp={selectedWorkout} closePopup={() => closePopup()} />}

        <GroupWorkoutHeader>
          <div className="group-workout-title-new">GROUP SESSION</div>
        </GroupWorkoutHeader>

        {!tabletLoading && canCreateGroupWorkouts && (
          <div className="create-workout-btn">
            <button className="button" onClick={navigateToCreateWorkout}>
              Create Group Workout
            </button>
          </div>
        )}

        {/* Handle Loading State */}
        {loading && <LoadingSpinner />}

        {/* Handle Error State */}
        {error && <p className="center-text">Error: {error}</p>}

        {/* Handle No Workouts Available */}
        {!loading && groupWorkouts && groupWorkouts.length === 0 && (
           <p className="center-text">No workouts available</p>
        )}

        {/* Display Group Workouts */}
        {groupWorkouts && groupWorkouts.length > 0 && (
          <div className="workouts-body">
            <div className="workout-list">
              {groupWorkouts.map((groupWorkout) => (
                <div className="workout-tile" key={groupWorkout.id} onClick={() => handleWorkoutClick(groupWorkout)}>
                  <div className="img-container">
                    <img
                      src={groupWorkout.thumbnailFile.blobUrl || 'https://via.placeholder.com/300x150'}
                      alt={groupWorkout.name}
                    />
                  </div>
                  <div className="workout-meta">
                    <div className="left">
                      <Icon name={IconNames.CLOCK} size={14} />
                    </div>
                    <div className="right">
                      <div style={{ paddingRight: '4px' }}>
                        <Icon name={IconNames.CLOCK} size={14} />
                      </div>
                      <div>{groupWorkout.duration} min.</div>
                    </div>
                  </div>

                  <div className="workout-info">
                    <h2>{groupWorkout.name}</h2>
                    <p>{groupWorkout.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <GroupWorkoutFooter />
      </div>
    </Main>
  )
}

export default GroupWorkoutsPage
