import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { PopupNames } from 'interfaces/popup'
import { ConnectionData } from 'interfaces/connection'
import { SnackbarNames } from 'interfaces/snackbar'
import { checkIfConnected } from 'lib/connection/connection'

export interface ConfigStore {
  isSoundActive: boolean
  isPaused: boolean
  activePopup: null | PopupNames
  activeSnackbar: null | SnackbarNames
  connectionState: ConnectionData
  actions: {
    setActivePopup: (popup: PopupNames | null) => void
    setActiveSnackbar: (snackbar: SnackbarNames | null) => void
    toggleSound: () => void
    setPaused: (paused: boolean) => void
    setConnectionState: (connectionState: ConnectionData) => void
  }
}

const useConfigStore = create<ConfigStore>((set, get) => ({
  isSoundActive: true,
  isPaused: false,
  activePopup: null,
  activeSnackbar: null,
  connectionState: {
    latestWifiStrength: null,
    averageReceivedPackets: null,
    latestRebootCount: null,
  },
  actions: {
    setActivePopup: (popup: PopupNames | null) => set(() => ({ activePopup: popup })),
    setActiveSnackbar: (snackbar: SnackbarNames | null) => set(() => ({ activeSnackbar: snackbar })),
    toggleSound: () => set(() => ({ isSoundActive: !get().isSoundActive })),
    setPaused: (paused: boolean) => set(() => ({ isPaused: paused })),
    setConnectionState: (connectionState: ConnectionData) =>
      set(() => {
        const setActiveSnackbar = get().actions.setActiveSnackbar
        const oldConnectionState = get().connectionState

        if (checkIfConnected(oldConnectionState, connectionState)) {
          setActiveSnackbar(SnackbarNames.CONNECTED)
        }

        return { connectionState }
      }),
  },
}))

// enable inspect from React dev tools
if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('CONFIG', useConfigStore)
}

export default useConfigStore
