// src/stores/groupWorkout/selectors.ts
import useGroupWorkoutStore from './index'

// Selectors
export const useGroupWorkouts = () => useGroupWorkoutStore((state) => state.groupWorkouts)

export const useCurrentGroupWorkout = () => useGroupWorkoutStore((state) => state.currentGroupWorkout)

export const useGroupWorkoutLoading = () => useGroupWorkoutStore((state) => state.loading)

export const useGroupWorkoutError = () => useGroupWorkoutStore((state) => state.error)

export const useGroupWorkoutActions = () => useGroupWorkoutStore((state) => state.actions)

export const useGroupWorkoutById = (id: string) =>
  useGroupWorkoutStore((state) => {
    if (!state.groupWorkouts) {
      return null;
    }
    return state.groupWorkouts.find((groupWorkout) => groupWorkout.id === id) || null
  })
