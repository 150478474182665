import React, { useState, useEffect } from 'react';

interface VideoDownloaderPlayerProps {
  downloadLocation: string;
  token: string | null;
}

function VideoDownloaderPlayer({ downloadLocation, token }: VideoDownloaderPlayerProps) {
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  useEffect(() => {
    async function downloadVideo() {
      try {
        const response = await fetch(downloadLocation, {
          headers: {
            'Authorization': token || '',
            'X-Authorization-Method': 'api',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to download file: ${response.status}`);
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        console.log(url)
        setVideoUrl(url);

        // Cleanup: revoke the URL when the component unmounts or when url changes
        return () => {
          if (url) {
            URL.revokeObjectURL(url);
          }
        };
      } catch (err) {
        console.error('Error downloading video:', err);
      }
    }

    downloadVideo();
  }, [downloadLocation, token]);

  return (
    <div>
      {videoUrl ? (
        <video controls width="600" src={videoUrl}>
          Your browser does not support the video tag.
        </video>
      ) : (
        <p>Loading video...</p>
      )}
    </div>
  );
}

export default VideoDownloaderPlayer;
